import { BiHomeAlt } from "react-icons/bi";
import { TbFileInvoice } from "react-icons/tb";
import { RxDashboard } from "react-icons/rx";
import { MdCasino } from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { CgUserList } from "react-icons/cg";
import { TbTableExport } from "react-icons/tb";
import { TbSettings } from "react-icons/tb";
import { RiAdminLine } from "react-icons/ri";
import { LuUserCheck } from "react-icons/lu";

export const SideBarData = [
	{
		title: "home",
		header_title: "Home - Dashboard",
		path: "/",
		icon: <BiHomeAlt size={22} />
	},
	{
		title: "invoices",
		header_title: "invoices",
		path: "/invoices",
		icon: <TbFileInvoice size={22} />,
		page_name: "invoices"
	},
	{
		title: "Main Dashboard",
		header_title: "casino",
		path: "/casino",
		icon: <RxDashboard size={22} />,
		page_name: "main_dashboard"
	},
	{
		title: "Casino Game Reports",
		header_title: "Casino Game Reports",
		path: "/casino-game-reports",
		icon: <MdCasino size={22} />,
		page_name: "casino_game_reports"
	},
	{
		title: "Game Rounds",
		header_title: "Casino Game Rounds",
		path: "/casino-game-rounds",
		icon: <MdCasino size={22} />,
		page_name: "game_rounds"
	},
	{
		title: "Transactions",
		header_title: "Transactions",
		path: "/transactions-per-casino",
		icon: <MdCasino size={22} />,
		page_name: "transactions_per_casino"
	},
	{
		title: "Player Reports",
		header_title: "Player Reports",
		path: "/player-reports",
		icon: <CgUserList size={22} />,
		page_name: "player_reports"
	},
	{
		title: "Financial Statements",
		header_title: "Financial Statements",
		path: "/financial-statements",
		icon: <TbTableExport size={22} />,
		page_name: "financial_statements"
	},
	{
		title: "Casino Settings",
		header_title: "Casino Settings",
		path: "/casino-settings",
		icon: <TbSettings size={22} />,
		page_name: "casino_settings"
	},
	{
		title: "2FA Settings",
		header_title: "2FA Settings",
		path: "/2fa-settings",
		icon: <TbSettings size={22} />,
		page_name: "2fa_settings"
	},
	{
		title: "Admin Reporting",
		icon: <RiAdminLine size={22} />,
		admin: true,

		child: [
			{
				title: "Finance",
				header_title: "Finance",
				path: "/finance",
				icon: <HiOutlineDocumentReport size={22} />,
				page_name: "finance_admin_reporting"
			},
			{
				title: "Financial Reports",
				header_title: "Financial Reports",
				path: "/financial-reports",
				icon: <TbTableExport size={22} />,
				page_name: "financial_reports_admin_reporting"
			},
			{
				title: "Game Reports",
				header_title: "Game Reports",
				path: "/game-reports",
				icon: <MdCasino size={22} />,
				page_name: "game_reports_admin_reporting"
			},
			{
				title: "User Permission",
				header_title: "User Permission",
				path: "/user-permission",
				icon: <LuUserCheck />,
				page_name: "user_permissions_admin_reporting"
			}
		]
	}
];
