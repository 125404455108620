import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import DataTable, { createTheme } from "react-data-table-component";
import Loader from "../Loader";
import { GiCreditsCurrency } from "react-icons/gi";
import DropDown from "../Common/DropDown";
import InputField from "../Common/InputField";
import { BsCalendar2Date } from "react-icons/bs";
import moment from "moment";
import { formatNum } from "../../utils";

const months = moment.monthsShort();
const AllBrandsGGRPerMonthTable = ({ startDate, endDate, currencies, refreshToggle, width }) => {
	const [currency, setCurrency] = useState("All");
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [data, setData] = useState([]);
	const [sort, setSort] = useState({ field: "", direction: "" });
	const [search, setSearch] = useState("");
    const [selectedYear, setSelectedYear] = useState(moment().year())
    const [selectedMonth, setSelectedMonth] = useState(0)
    const [yearOptions, setYearOptions] = useState()

    const monthOptions = [
        "All",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    useEffect(() => {
        let arr = [];
        for (let i = 2021; i <= new Date().getFullYear(); i++) {
          arr.push(i);
        }
        setYearOptions(arr);
      }, []);

	const getData = async () => {
		setErrorMessage("");
		const casino = JSON.parse(localStorage?.getItem("currentCasino"));
		const allCasinos = JSON.parse(localStorage.getItem("userInformation"));
		const brands = Object.groupBy(allCasinos?.casino_information, (casino) => casino.brand);

		setLoading(true);

		const params = new URLSearchParams({
			currency: currency === "All" ? "" : currency,
			start: startDate + "T00:00:00Z",
			end: endDate + "T23:59:59Z",
			token: casino?.casino_token,
			casino: casino?.casino_key,
			sortBy: sort.field,
			orderBy: sort.direction,
			month: selectedMonth == 0 ? "" : moment(selectedMonth, 'MM').format('MMM'),
			year: selectedYear
		});

		await axios
			.post(`${process.env.REACT_APP_API_DOMAIN}/reports/transactions/dashboard/brands-ggr-by-each-month?${params.toString()}`, {
				brands
			})
			.then((response) => {
				if (response.data.status === 200) {
					setData(response?.data?.response?.data);
				}
			})
			.catch((error) => {
				setErrorMessage(error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getData();
	}, [refreshToggle, currency, sort, selectedMonth, selectedYear]);

	createTheme(
		"solarized",
		{
			text: {
				primary: "#ffffff",
				secondary: "#8651d6"
			},
			background: {
				default: "rgb(22 22 22 / 20%)"
			},
			context: {
				background: "#cb4b16",
				text: "#FFFFFF"
			},
			divider: {
				default: "rgba(225,225,225,0.08)"
			},
			pagination: {
				fontSize: "1rem",
				fontFamily: "inherit",
				textAlign: "right"
			}
		},
		"dark"
	);

	const monthColumns = months.map((month, index) => {
		return {
			name: month,
			selector: (row) => row?.monthsData[index]?.tableGGR ? formatNum.format(row?.monthsData[index]?.tableGGR) : 0,
			sortable: false,
			width: "7%"
		};
	});

	const columns = [
		{
			name: "Brand",
			selector: (row) => row?.name,
			sortable: true,
			sortField: "name",
			width: '10%'
		},
		...monthColumns,
		{
			name: "GGR",
			selector: (row) => `${row?.GGR?.toFixed(2)} ${row?.currency}`,
			sortable: true,
			sortField: "GGR",
			width: "10%"
		},
		{
			name: "Fee UG (%)",
			selector: (row) => row?.royaltiesKey,
			sortable: true,
			sortField: "royaltiesKey",
			width: "10%"
		},
		{
			name: "Fee UG",
			selector: (row) => row?.royalties?.toFixed(2),
			sortable: true,
			sortField: "royalties"
		}
	];

	const handleSort = (column, sortDirection) => {
		setSort({ field: column.sortField, direction: sortDirection });
	};

	const customFilter = (data) => {
		return data?.tableData?.filter((item) => {
			if (search === "") {
				return item;
			} else {
				return item.name.toLowerCase().includes(search.toLowerCase());
			}
		});
	};

	return (
		<>
			<div className="finance_heapchart mb_30">
				<Row className="align-items-center">
					<Col lg={5}>
						<h5 className="h5_title">All Brands GGR each Month</h5>
					</Col>
                    <Col lg={2}>
                        <div className="datepicker_right">
                            <label className="form_input_label">
                                <div className="form_input_icon">
                                <BsCalendar2Date size={20} />
                                </div>
                                Select Month
                            </label>
                            <DropDown
                                placeholder="Select Month"
                                options={monthOptions}
                                dataKey=""
                                isSelected={monthOptions[selectedMonth]}
                                activeOption={monthOptions[selectedMonth]}
                                activeOptionId={monthOptions[selectedMonth]}
                                setOption={(data, index) => {
                                setSelectedMonth(index);
                                }}
                            />
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="datepicker_right">
                            <label className="form_input_label">
                                <div className="form_input_icon">
                                <BsCalendar2Date size={20} />
                                </div>
                                Select Year
                            </label>
                            <DropDown
                                placeholder="Select Year"
                                options={yearOptions}
                                dataKey=""
                                isSelected={selectedYear}
                                activeOption={selectedYear}
                                activeOptionId={selectedYear}
                                setOption={(data, index) => {
                                    setSelectedYear(data);
                                }}
                            />
                        </div>
                    </Col>
					<Col lg={3}>
						<div className="datepicker_right">
							<label className="form_input_label">
								<div className="form_input_icon">
									<GiCreditsCurrency size={20} />
								</div>
								Select Currency
							</label>

							<DropDown
								placeholder="Select Currency"
								options={currencies}
								dataKey="currency_abrv"
								isSelected={currency}
								activeOption={currency}
								activeOptionId={currency}
								setOption={(data, index) => {
									setCurrency(data?.currency_abrv);
								}}
							/>
						</div>
					</Col>
				</Row>
			</div>

			{errorMessage ? (
				<p style={{ textAlign: "center", color: "red" }}> {errorMessage}</p>
			) : (
				<>
					<Col lg={4}>
						<InputField
							type="search"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							placeholder="Search by brand"
						/>
					</Col>
					<DataTable
						data={customFilter(data)}
						columns={columns}
						customStyles={{
							headCells: {
								style: {
									fontSize: '14px',
									fontWeight: 'bold',
									backgroundColor: '#2F2F2F'
								}
							}
						}}
						theme="solarized"
						onSort={handleSort}
						sortServer
					/>
				</>
			)}

			{loading ? <Loader /> : ""}
		</>
	);
};

export default AllBrandsGGRPerMonthTable;
