import { useEffect, useRef, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoSearch } from "react-icons/io5";

export default function DropDown({ activeOption, isSelected, setOption, options, activeOptionId, dataKey = "", placeholder, optionModify, dropDownOpenTop, className }) {
	const [popularDropDown, setPopularDropdown] = useState(false);
	const [searched, setSearched] = useState("");

	const dropDownRef = useRef();
	const inputRef = useRef();
	// const [width, setWidth] = useState();

	// useEffect(() => {
	//     setWidth(window.innerWidth);
	//     const handleResize = () => {
	//         setWidth(window.innerWidth);
	//     };

	//     window.addEventListener("resize", handleResize);

	//     return () => {
	//         window.removeEventListener("resize", handleResize);
	//     };
	// }, []);

	useEffect(() => {
		if (popularDropDown) {
			inputRef.current.focus();
		} else {
			inputRef.current.blur();
		}
	}, [popularDropDown]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
				setPopularDropdown(false);
			}
		};

		document.addEventListener("click", handleClickOutside);

		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	return (
		<div
			className={` ${className && className} dropdown_wp currency_dropdown ${dropDownOpenTop ? "dropdown_open_top" : ""} ${popularDropDown ? "active_dropdown" : ""}`}
			ref={dropDownRef}
		>
			<button
				type="button"
				className="sec_btn dropdown_btn transparent_btn"
				onClick={() => setPopularDropdown((prev) => !prev)}
			>
				{isSelected === -1 || isSelected === undefined ? placeholder : activeOption}
				<span className="dropdown_icon">
					<MdOutlineKeyboardArrowDown size={22} />
				</span>
			</button>

			<div className="dropdown_content ">
				<div className="dropdown_search">
					<IoSearch size={26} />
					<input
						ref={inputRef}
						type="search"
						className="form_input"
						placeholder="Search.."
						autoFocus
						onChange={(e) => setSearched(e.target.value.trim())}
					/>
				</div>

				<ul className="dropdown_list">
					{options?.map((data, index) => {
						let dataKeyOption, activeOptionClass;

						// if object key
						if (dataKey.length || !Array.isArray(options)) {
							dataKeyOption = data[dataKey];
							if (optionModify?.prefix && optionModify?.target === "index") {
								dataKeyOption = `${optionModify?.prefix}${index}`;
							}
							// activeOptionClass = data.id === activeOptionId ? "active-option" : "";
							activeOptionClass = dataKeyOption === activeOptionId ? "active-option" : null;
						} else {
							dataKeyOption = data;
							if (optionModify?.prefix && optionModify?.target === "index") {
								dataKeyOption = `${optionModify?.prefix}${index}`;
							}
							activeOptionClass = data === activeOption ? "active-option" : null;
						}

						// searched options
						if (searched.length && !dataKeyOption?.toString().toLowerCase().includes(searched.toLocaleLowerCase())) {
							return;
						}

						return (
							<li
								className={activeOptionClass}
								key={dataKeyOption + Math.random()}
								onClick={() => {
									setOption(data, index);
									setPopularDropdown((prev) => !prev);
								}}
							>
								{dataKeyOption}
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
}
