import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { FaRegUserCircle } from "react-icons/fa";
import Logo from "../../assets/images/logo.png";
import CasinoName from "./CasinoName";
import UserProfile from "./UserProfile";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import DropDown from "../Common/DropDown";
import { UserInfoState } from "../../context/UserInformationContext";

const Header = ({ header_menu_button, mobileHeader, sideBar, sideBarButton }) => {
	const { userInfo } = UserInfoState();
	const [pageName, setPageName] = useState();
	const [playerToggle, setPlayerToggle] = useState(false);
	const [activeDropdown, setActiveDropdown] = useState("");
	const [width, setWidth] = useState();
	const location = useLocation();
	const navigate = useNavigate();
	const [casinoName, setCasinoName] = useState([]);
	const [casinoInfo, setCasinoInfo] = useState([]);
	const [selectedCasio, setSelectedCasino] = useState();

	// /** On load to get user data Start */
	useEffect(() => {
		setCasinoInfo(userInfo?.casino_information);
	}, [userInfo]);

	useEffect(() => {
		if (casinoInfo?.length > 0) {
			var casinoDetails = localStorage?.getItem("currentCasino");
			if (casinoDetails === null) {
				localStorage.setItem("currentCasino", JSON.stringify(casinoInfo[0]));
				casinoDetails = localStorage?.getItem("currentCasino");
			}
			setSelectedCasino(JSON.parse(casinoDetails));
		}
	}, [casinoInfo]);

	// sorting by casino name
	useEffect(() => {
		let cname = casinoInfo?.sort((a, b) => a?.casino_name?.localeCompare(b?.casino_name));
		setCasinoName(cname);
	}, [casinoInfo, casinoName]);

	console.log(casinoName, "casinoName");

	useEffect(() => {
		// if (location.pathname === "/login" && localStorage.getItem("user-authorization-token")) {
		//     navigate('/');
		// }
		if (location.pathname === "/player-reports") {
			setPageName("Player Reports");
		} else if (location.pathname === "/casino-settings") {
			setPageName("Casino Settings");
		} else if (location.pathname === "/2fa-settings") {
			setPageName("2FA Settings");
		} else if (location.pathname === "/casino") {
			setPageName("Main Dashboard");
		} else if (location.pathname === "/casino-game-reports") {
			setPageName("Casino Game Reports");
		} else if (location.pathname === "/casino-game-rounds") {
			setPageName("Casino Game Rounds");
		} else if (location.pathname === "/financial-statements") {
			setPageName("Financial Statements");
		} else if (location.pathname === "/invoices") {
			setPageName("Invoices");
		} else if (location.pathname === "/finance") {
			setPageName("Finance");
		} else if (location.pathname === "/financial-reports") {
			setPageName("Financial Reports");
		} else if (location.pathname === "/player") {
			setPageName("Player");
		} else if (location.pathname === "/game-reports") {
			setPageName("Game Reports");
		} else if (location.pathname === "/user-permission") {
			setPageName("User Permission");
		} else if (location.pathname === "/transactions-per-casino") {
			setPageName("Transactions");
		} else {
			setPageName("Home - Dashboard");
		}
	}, [location.pathname]);

	useEffect(() => {
		setWidth(window.innerWidth);
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const playerToggleButton = () => {
		setPlayerToggle(!playerToggle);
	};
	useEffect(() => {
		document.title = `${pageName} - Casino and Sports Betting Dashboard`;
	}, [pageName]);

	return (
		<>
			<button className="sidebar_collapse_button for_des">
				{sideBar ? (
					<MdOutlineKeyboardArrowRight
						size={22}
						onClick={sideBarButton}
					/>
				) : (
					<MdOutlineKeyboardArrowLeft
						size={22}
						onClick={sideBarButton}
					/>
				)}
			</button>

			<button
				className="sidebar_collapse_button for_mob"
				onClick={header_menu_button}
			>
				<AiOutlineClose size={25} />
			</button>

			<header className="site_header">
				<div className="top_header for_mob">
					<Link
						to="/"
						title="Logo"
						className="site_logo"
					>
						<img
							width={190}
							height={60}
							src={Logo}
							alt="Logo"
						/>
					</Link>

					<button
						type="button"
						className="player_toggle menu_toggle"
						onClick={playerToggleButton}
					>
						<FaRegUserCircle size={25} />
					</button>

					<button
						type="button"
						className="menu_toggle"
						onClick={header_menu_button}
					>
						<AiOutlineMenu size={25} />
					</button>
				</div>

				<div className="main_header_content">
					<h5 className="my_0">{pageName}</h5>

					<div className={`header_right ${playerToggle ? "player_open" : ""}`}>
						<ul>
							<li>
								{/* <CasinoName
									activeDropdown={activeDropdown}
									setActiveDropdown={setActiveDropdown} // casino
									width={width}
								/> */}
								<DropDown
									className="brand_dropdown"
									placeholder="Select Brand"
									options={casinoName}
									dataKey="casino_name"
									isSelected={selectedCasio?.casino_name}
									activeOption={selectedCasio?.casino_name}
									activeOptionId={selectedCasio?.casino_name}
									setOption={(data, index) => {
										localStorage.setItem("currentCasino", JSON.stringify(casinoInfo[index]));
										const casinoDetails = localStorage?.getItem("currentCasino");
										setSelectedCasino(JSON.parse(casinoDetails));
										window.location.reload(false);
									}}
								/>
							</li>

							<li>
								<UserProfile
									activeDropdown={activeDropdown}
									setActiveDropdown={setActiveDropdown} // profile
									width={width}
								/>
							</li>
						</ul>
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;
