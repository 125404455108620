import axios from "axios";
import React, { useState } from "react";
import Loader from "../../Loader";
import { FiSettings } from "react-icons/fi";
import { AiOutlineExport } from "react-icons/ai";
import { Col, Row } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";

const ExcelJS = require("exceljs");

const monthsList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const sortByKey = (a, b) => {
	if (a.casino < b.casino) {
		return -1;
	}
	if (a.casino > b.casino) {
		return 1;
	}
	return 0;
};

const MonthlySummaries = ({ selectedGameType, startDate, endDate, currency }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [summaryData, setSummaryData] = useState([]);

	const downloadExcel = async (data) => {
		const workbook = new ExcelJS.Workbook();
		const sheet = workbook.addWorksheet("Monthly Game Summaries");
		const header = ["Brand", "Currency", "Bets", "Wins", "RTP", "GGR", "Number of Txns", "Currency Converter", "GGR", "Royalties (%)", "Royalties", "Bonus Deduction (%)", "Bonus Deduction", "Net Royalties"];
		// const headerKeys = ["casino", "loses", "wins", "GGR"];

		sheet.columns = [
			{
				header: "Brand",
				key: "casino",
				width: 30
			},
			{
				header: "Currency",
				key: "currency",
				width: 15
			},
			{
				header: "Bets",
				key: "loses",
				width: 15
			},
			{
				header: "Wins",
				key: "wins",
				width: 15
			},
			{
				header: "RTP",
				key: "RTP",
				width: 15
			},
			{
				header: "GGR",
				key: "GGR",
				width: 15
			},
			{
				header: "Number of Txns",
				key: "totalTxns",
				width: 15
			},
			{
				header: "Currency Converter",
				key: "conversionRate",
				width: 20
			},
			{
				header: "GGR",
				key: "GGRWithCurrency",
				width: 15
			},
			{
				header: "Royalties (%)",
				key: "royaltiesKey",
				width: 15
			},
			{
				header: "Royalties",
				key: "royalties",
				width: 15
			},
			{
				header: "Bonus Deduction (%)",
				key: "bonusDeductionKey",
				width: 20
			},
			{
				header: "Bonus Deduction",
				key: "bonusDeduction",
				width: 20
			},
			{
				header: "Net Royalties",
				key: "netRoyalties",
				width: 15
			}
		];

		const colors = {
			dark: {
				type: "pattern",
				pattern: "solid",
				fgColor: { argb: "1F3864" }
			},
			light: {
				type: "pattern",
				pattern: "solid",
				fgColor: { argb: "ADB9CA" }
			},
			fullDark: {
				type: "pattern",
				pattern: "solid",
				fgColor: { argb: "002060" }
			},
			grey: {
				type: "pattern",
				pattern: "solid",
				fgColor: { argb: "c8c8c8" }
			}
		};

		const borders = {
			top: { style: "thin" },
			left: { style: "thin" },
			bottom: { style: "thin" },
			right: { style: "thin" }
		};

		sheet.getRow(1).font = {
			bold: true
		};

		const start = new Date(startDate);
		const end = new Date(endDate);
		const startDateFormatted = `${start.getDate().toString().padStart(2, 0)} ${monthsList[start.getMonth()]}-${start.getFullYear()}`;
		const endDateFormatted = `${end.getDate().toString().padStart(2, 0)} ${monthsList[end.getMonth()]}-${end.getFullYear()}`;

		const transactionsRange = startDate === endDate ? `of ${startDateFormatted}` : `between ${startDateFormatted} - ${endDateFormatted}`;

		sheet.mergeCells("A1", "N1");
		sheet.getCell("A1").value = `Game Bets Transactions ${transactionsRange}`;
		sheet.getCell("A1").fill = colors.light;
		sheet.getRow(1).alignment = { vertical: "middle", horizontal: "center" };

		sheet.addRow();

		data.forEach((mainData, mainIndex) => {
			// Normal data write in sheet
			mainData.data.forEach((data, index) => {
				// Month and Game Type Header
				sheet.addRow([data.month, data.game_type]).eachCell((cell) => {
					sheet.getCell(cell.address).fill = colors.dark;
					sheet.getCell(cell.address).font = { color: { argb: "FFFFFF" }, bold: true };
					if (cell._column._number === 1) {
						sheet.getCell(cell.address).alignment = { horizontal: "right" };
						sheet.getCell(cell.address).border = {
							top: { style: "thin" },
							left: { style: "thin" },
							bottom: { style: "thin" }
						};
					} else {
						sheet.getCell(cell.address).border = {
							right: { style: "thin" },
							top: { style: "thin" },
							bottom: { style: "thin" }
						};
					}
				});

				// Brand row header
				sheet.addRow(header).eachCell((cell) => {
					if (["royalties", "GGRWithCurrency"].includes(cell._column._key)) {
						sheet.getCell(cell.address).value = cell._column._header + " " + mainData.invoiceCurrency;
					}
					sheet.getCell(cell.address).fill = colors.dark;
					sheet.getCell(cell.address).font = { color: { argb: "FFFFFF" }, bold: true };
					sheet.getCell(cell.address).border = borders;
				});

				// Main data body rows
				sheet.lastRow.alignment = { horizontal: "center" };

				data.currencies.forEach((cData, cIndex) => {
					if (!cData.currencyExist) return;

					cData.casino = data.casino;
					cData.royaltiesKey = data.royaltiesKey;
					cData.bonusDeductionKey = data.bonusDeductionKey;

					if (cData.currencyType === "crypto") {
						cData.currency = cData.currency + " (₿)";
					}

					sheet.addRow(cData).eachCell((cell) => {
						if ([3, 4, 5, 9, 10, 11, 13, 14].includes(cell._column._number)) {
							sheet.getCell(cell.address).fill = colors.light;
						}
						if (["loses", "wins", "GGR"].includes(cell._column._key)) {
							sheet.getCell(cell.address).numFmt = `###0.00;[Red]-###0.00`;
						}
						if (["GGRWithCurrency", "royalties", "bonusDeduction", "netRoyalties"].includes(cell._column._key)) {
							sheet.getCell(cell.address).numFmt = `${mainData.currencySymbol}###0.00;[Red]-${mainData.currencySymbol}###0.00`;
						}
						sheet.getCell(cell.address).border = borders;
					});
				});

				// For unrealized currency data
				if (data.currencyNotExistStatus) {
					data.currencyNotExistData.forEach((cData) => {
						sheet.addRow([`Unrealized currency - ${cData.currency}`, cData.currency, cData.loses, cData.wins, cData.GGR, cData.totalTxns, "", "", "", "", "", "", ""]).eachCell((cell) => {
							sheet.getCell(cell.address).font = { color: { argb: "C00000" } };
							if ([2, 3, 4, 5].includes(cell._column._number)) {
								sheet.getCell(cell.address).fill = colors.grey;
							}
							if (["loses", "wins", "GGR"].includes(cell._column._key)) {
								sheet.getCell(cell.address).numFmt = `###0.00;[Red]-###0.00`;
							}
							sheet.getCell(cell.address).border = borders;
						});
					});
					data.currencyNotExistData.forEach((cData) => {
						sheet.addRow([`Convert ${cData.currency} to ${mainData.invoiceCurrency}`, "", "", "", "", "", "", "", data.royaltiesKey, "", data.bonusDeductionKey, "", ""]).eachCell((cell) => {
							if ([3, 4, 5, 9, 10, 11, 13, 14].includes(cell._column._number)) {
								sheet.getCell(cell.address).fill = colors.light;
							}
							sheet.getCell(cell.address).border = borders;
						});
					});
				}

				// Total Footer
				sheet.addRow(["TOTAL", mainData.invoiceCurrency, "", "", "", "", data.totalTxns, "", data.GGRWithCurrency, "", data.royalties, "", data.bonusDeduction, data.netRoyalties]).eachCell((cell) => {
					if (["loses", "wins", "GGR"].includes(cell._column._key)) {
						sheet.getCell(cell.address).numFmt = `###0.00;[Red]-###0.00`;
					}
					if (["GGRWithCurrency", "royalties", "bonusDeduction", "netRoyalties"].includes(cell._column._key)) {
						sheet.getCell(cell.address).numFmt = `${mainData.currencySymbol}###0.00;[Red]-${mainData.currencySymbol}###0.00`;
					}
					if (cell._column._number === 1) {
						sheet.getCell(cell.address).alignment = { horizontal: "center" };
					}
					sheet.getCell(cell.address).font = { bold: true };
					sheet.getCell(cell.address).border = borders;
				});

				// For unrealized update totals footer
				if (data.currencyNotExistStatus) {
					sheet.addRow(["UPDATED TOTAL", mainData.invoiceCurrency, "", "", "", "", "", "", 0, "", 0, "", 0, 0]).eachCell((cell) => {
						if (["loses", "wins", "GGR"].includes(cell._column._key)) {
							sheet.getCell(cell.address).numFmt = `###0.00;[Red]-###0.00`;
						}
						if (["royalties", "bonusDeduction", "netRoyalties"].includes(cell._column._key)) {
							sheet.getCell(cell.address).numFmt = `${mainData.currencySymbol}###0.00;[Red]-${mainData.currencySymbol}###0.00`;
						}
						if (cell._column._number === 1) {
							sheet.getCell(cell.address).alignment = { horizontal: "center" };
						}
						sheet.getCell(cell.address).font = { bold: true };
						sheet.getCell(cell.address).border = borders;
					});
				}

				// To add new empty row
				sheet.addRow();
			});

			// For not exists currencies
			if (mainData.currencyNotExistStatus) {
				const notExistsCurrencies = mainData.currencyNotExistData.map((currency) => currency.currency);

				sheet.addRow([`Note: Currencies '${notExistsCurrencies.join("', '")}' rates not exists in the database and not calculated in the RNS GRAND TOTAL please convert it manually.`]).eachCell((cell) => {
					sheet.getCell(cell.address).font = { color: { argb: "C00000" }, bold: true };
					sheet.getCell(cell.address).fill = colors.grey;
				});
				sheet.mergeCells(sheet.rowCount, 1, sheet.rowCount, sheet.columnCount);
			}

			// Grand Total write in sheet
			sheet.addRow(["RNG GRAND TOTAL", mainData.invoiceCurrency, "", "", "", "", "", "", mainData.GGRWithCurrency, "", mainData.royalties, "", mainData.bonusDeduction, mainData.netRoyalties]).eachCell((cell) => {
				if (cell._column._number === 1) {
					sheet.getCell(cell.address).alignment = { horizontal: "center" };
				}
				if (["royalties", "GGRWithCurrency", "bonusDeduction", "netRoyalties"].includes(cell._column._key)) {
					sheet.getCell(cell.address).numFmt = `${mainData.currencySymbol}###0.00;[Red]-${mainData.currencySymbol}###0.00`;
				}
				sheet.getCell(cell.address).fill = colors.fullDark;
				sheet.getCell(cell.address).font = { color: { argb: "FFFFFF" }, bold: true };
				sheet.getCell(cell.address).border = borders;
				if (["royalties", "bonusDeduction", "netRoyalties"].includes(cell._column._key)) {
					sheet.getCell(cell.address).numFmt = `${mainData.currencySymbol}###0.00;[Red]-${mainData.currencySymbol}###0.00`;
				}
			});

			// For not exists currencies
			if (mainData.currencyNotExistStatus) {
				sheet.addRow(["UPDATED - RNG GRAND TOTAL", mainData.invoiceCurrency, "", "", "", "", "", "", "", "", "", "", "", ""]).eachCell((cell) => {
					if (cell._column._number === 1) {
						sheet.getCell(cell.address).alignment = { horizontal: "center" };
					}
					if (["loses", "wins", "GGR", "royalties", "GGRWithCurrency", "bonusDeduction", "netRoyalties"].includes(cell._column._key)) {
						sheet.getCell(cell.address).numFmt = `${mainData.currencySymbol}###0.00;[Red]-${mainData.currencySymbol}###0.00`;
					}
					sheet.getCell(cell.address).fill = colors.fullDark;
					sheet.getCell(cell.address).font = { color: { argb: "FFFFFF" }, bold: true };
					sheet.getCell(cell.address).border = borders;
				});
			}

			sheet.addRow();
		});

		await workbook.xlsx.writeBuffer().then((data) => {
			const blob = new Blob([data], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet"
			});

			const url = window.URL.createObjectURL(blob);
			const anchor = document.createElement("a");
			anchor.href = url;
			anchor.download = "monthly-game-summary.xlsx";
			anchor.click();
			window.URL.revokeObjectURL(url);
		});
	};

	/**
	 * Export handler
	 */
	const exportDetailedGameReportsHandler = () => {
		setLoading(true);
		setError("");

		// const casino = JSON.parse(localStorage?.getItem("currentCasino"));
		const gameType = !selectedGameType || selectedGameType === "All" ? "" : selectedGameType;

		const allCasinos = JSON.parse(localStorage.getItem("userInformation"));

		const devPoint = "dev-";

		axios
			.post(`${process.env.REACT_APP_API_DOMAIN}/games/reports/${devPoint}monthly-game-summaries`, {
				casinos: allCasinos?.casino_information || [],
				currency: currency,
				start: `${startDate}T00:00:00Z`,
				end: `${endDate}T23:59:59Z`,
				gameType: gameType
			})
			.then((response) => {
				if (response.data?.status === 200) {
					if (response.data?.data?.length === 0) {
						setError("There are no records found");
					}

					response.data?.data.map((data) => {
						return {
							...data,
							data: data.data?.sort(sortByKey)
						};
					});

					setSummaryData(response.data?.data);
				} else {
					setError(response.data?.message);
				}
			})
			.catch((error) => {
				setError(error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	createTheme(
		"solarized",
		{
			text: {
				primary: "#ffffff",
				secondary: "#8651d6"
			},
			background: {
				default: "rgb(22 22 22 / 20%)"
			},
			context: {
				background: "#cb4b16",
				text: "#FFFFFF"
			},
			divider: {
				default: "rgba(225,225,225,0.08)"
			}
		},
		"dark"
	);

	const renderAmountWithCurrency = (amount, currency) => {
		const res = Number(amount)?.toLocaleString("en-US", {
			style: "currency",
			currency: currency,
			minimumFractionDigits: 2
		});
		return res;
	};

	const newColumns = [
		{
			name: "",
			selector: (row) => "RNG GRAND TOTAL",
			width: "210px",
			wrap: true
		},
		{
			name: "",
			selector: (row) => row?.invoiceCurrency,
			width: "90px"
		},
		{
			name: "",
			selector: (row) => "",
			width: "105px"
		},
		{
			name: "",
			selector: (row) => "",
			width: "105px"
		},
		{
			name: "",
			selector: (row) => "",
			width: "90px"
		},
		{
			name: "",
			selector: (row) => "",
			width: "120px"
		},
		{
			name: "",
			selector: (row) => "",
			width: "130px"
		},
		{
			name: "",
			selector: (row) => "",
			width: "150px"
		},
		{
			name: "",
			selector: (row) => renderAmountWithCurrency(row?.GGRWithCurrency, row?.invoiceCurrency),
			width: "110px"
		},
		{
			name: "",
			selector: (row) => "",
			width: "110px"
		},
		{
			name: "",
			selector: (row) => <div className="selected_col">{renderAmountWithCurrency(row?.royalties, row?.invoiceCurrency)}</div>,
			width: "120px"
		},
		{
			name: "",
			selector: (row) => "",
			width: "120px"
		},
		{
			name: "",
			selector: (row) => <div className="selected_col">{renderAmountWithCurrency(row?.bonusDeduction, row?.invoiceCurrency)}</div>,
			width: "125px"
		},
		{
			name: "",
			selector: (row) => <div className="selected_col">{renderAmountWithCurrency(row?.netRoyalties, row?.invoiceCurrency)}</div>,
			width: "115px"
		}
	];

	return (
		<>
			<Row className="align-items-center row-gap-2 mb_30">
				<Col lg={6}>
					<h6>Monthly Summaries</h6>
				</Col>

				<Col lg={6}>
					<div className="button_group justify-content-end">
						<button
							type="button"
							className="sec_btn"
							onClick={() => downloadExcel(summaryData)}
							disabled={loading || summaryData?.length === 0}
						>
							Download Excel <AiOutlineExport />
						</button>
						<button
							type="button"
							className="sec_btn"
							onClick={exportDetailedGameReportsHandler}
						>
							Generate <FiSettings />
						</button>
					</div>

					{loading ? <Loader /> : error ? <p>{error}</p> : ""}
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<div className="player_reports_table_wrapper">
						{summaryData?.map((data, index) => {
							const result = data?.data?.reduce((acc, obj) => {
								const key = obj?.casino;
								(acc[key] = acc[key] || []).push(obj);
								return acc;
							}, {});

							const arrayOfArrays = Object.values(result);

							return (
								<React.Fragment key={index}>
									<h2>
										Invoice Currency: {data.invoiceCurrency} ({data.currencySymbol})
									</h2>
									{arrayOfArrays?.map((arr, index) => {
										const result = arr?.reduce((acc, obj) => {
											const key = obj?.month;
											(acc[key] = acc[key] || []).push(obj);
											return acc;
										}, {});

										const innerArrays = Object.values(result);

										return (
											<React.Fragment key={index}>
												{innerArrays?.map((iArr, index) => {
													return (
														<React.Fragment key={index}>
															{iArr?.map((i, index) => {
																return (
																	<React.Fragment key={index}>
																		<div className="player_reports_table slot_table mb_30">
																			<h6 className="mb_10">
																				{i?.casino} · {i?.month}
																			</h6>
																			<div
																				className="table_loader_wp"
																				style={{ minHeight: "70px" }}
																			>
																				<DataTable
																					theme="solarized"
																					data={[
																						...i?.currencies,
																						{
																							isTotal: true,
																							currency: <b>{i?.conversionTo}</b>,
																							totalTxns: <b className={`${Number(i?.totalTxns) < 0 ? "error_msg" : ""}`}>{i?.totalTxns}</b>,
																							conversionRate: "",
																							currencyExist: "",
																							royaltiesKey: "",
																							royalties: i?.royalties,
																							bonusDeductionKey: "",
																							bonusDeduction: i?.bonusDeduction,
																							netRoyalties: i?.netRoyalties,
																							GGRWithCurrency: i?.GGRWithCurrency
																						}
																					]}
																					columns={[
																						{
																							name: "Brand",
																							selector: (row) => {
																								return row?.isTotal ? <b>Total</b> : i?.casino;
																							},
																							width: "210px",
																							wrap: true
																						},
																						{
																							name: "Currency",
																							selector: (row) => row?.currency,
																							width: "90px"
																						},
																						{
																							name: "Bets",
																							selector: (row) => <div className={`${Number(row?.loses) < 0 ? "error_msg" : ""}`}>{row?.loses?.toFixed(2)}</div>,
																							width: "105px"
																						},
																						{
																							name: "Wins",
																							selector: (row) => <div className={`${Number(row?.wins) < 0 ? "error_msg" : ""}`}>{row?.wins?.toFixed(2) || ""}</div>,
																							width: "105px"
																						},
																						{
																							name: "RTP",
																							selector: (row) => <div className={`${Number(row?.RTP) < 0 ? "error_msg" : ""}`}>{row?.RTP?.toFixed(2) || ""}</div>,
																							width: "90px"
																						},
																						{
																							name: "GGR",
																							selector: (row) =>
																								!row?.isTotal ? <div className={`${Number(row?.GGR) < 0 ? "error_msg" : ""}`}>{Number(row?.GGR)?.toFixed(2)}</div> : "",
																							width: "120px"
																						},
																						{
																							name: "Number of Txns",
																							selector: (row) => <div className={`${Number(row?.totalTxns) < 0 ? "error_msg" : ""}`}>{row?.totalTxns}</div>,
																							width: "130px"
																						},
																						{
																							name: "Currency Converter",
																							selector: (row) => <div className={`${Number(row?.conversionRate) < 0 ? "error_msg" : ""}`}>{row?.conversionRate}</div>,
																							width: "150px"
																						},
																						{
																							name: `GGR ${data?.invoiceCurrency}`,
																							selector: (row) =>
																								!row?.isTotal ? (
																									<div className={`${Number(row?.GGRWithCurrency) < 0 ? "error_msg" : ""}`}>
																										{renderAmountWithCurrency(row?.GGRWithCurrency, data?.invoiceCurrency)}
																									</div>
																								) : (
																									<b className={`${Number(i?.GGRWithCurrency) < 0 ? "error_msg" : ""}`}>
																										{renderAmountWithCurrency(row?.GGRWithCurrency, data?.invoiceCurrency)}
																									</b>
																								),
																							width: "110px"
																						},
																						{
																							name: "Royalties (%)",
																							selector: (row) => <div className={`${Number(row?.royaltiesKey) < 0 ? "error_msg" : ""}`}>{row?.royaltiesKey}</div>,
																							width: "110px"
																						},
																						{
																							name: `Royalties ${data?.invoiceCurrency}`,
																							selector: (row) =>
																								!row?.isTotal ? (
																									<div className={`${Number(row?.royalties) < 0 ? "error_msg" : ""}`}>
																										{renderAmountWithCurrency(row?.royalties, data?.invoiceCurrency)}
																									</div>
																								) : (
																									<b className={`${Number(row?.royalties) < 0 ? "error_msg" : ""}`}>
																										{renderAmountWithCurrency(row?.royalties, data?.invoiceCurrency)}
																									</b>
																								),
																							width: "120px"
																						},
																						{
																							name: "Bonus DED. (%)",
																							selector: (row) => <div className={`${Number(row?.bonusDeductionKey) < 0 ? "error_msg" : ""}`}>{row?.bonusDeductionKey}</div>,
																							width: "120px"
																						},
																						{
																							name: "Bonus Deduction",
																							selector: (row) =>
																								row?.isTotal ? (
																									<b className={`${Number(row?.bonusDeductionKey) < 0 ? "error_msg" : ""}`}>
																										{renderAmountWithCurrency(row?.bonusDeduction, data?.invoiceCurrency)}
																									</b>
																								) : (
																									<div className={`${Number(row?.bonusDeductionKey) < 0 ? "error_msg" : ""}`}>
																										{renderAmountWithCurrency(row?.bonusDeduction, data?.invoiceCurrency)}
																									</div>
																								),
																							width: "125px"
																						},
																						{
																							name: "Net Royalties",
																							selector: (row) =>
																								row?.isTotal ? (
																									<b className={`${Number(row?.netRoyalties) < 0 ? "error_msg" : ""}`}>
																										{renderAmountWithCurrency(row?.netRoyalties, data?.invoiceCurrency)}
																									</b>
																								) : (
																									<div className={`${Number(row?.netRoyalties) < 0 ? "error_msg" : ""}`}>
																										{renderAmountWithCurrency(row?.netRoyalties, data?.invoiceCurrency)}
																									</div>
																								),
																							width: "115px"
																						}
																					]}
																				/>
																			</div>
																		</div>
																	</React.Fragment>
																);
															})}
														</React.Fragment>
													);
												})}
											</React.Fragment>
										);
									})}

									<div className="player_reports_table slot_table slot_one_row_table">
										<div
											className="table_loader_wp"
											style={{ minHeight: "70px" }}
										>
											<DataTable
												theme="solarized"
												columns={newColumns}
												data={[data]}
											/>
										</div>
									</div>
								</React.Fragment>
							);
						})}
					</div>
				</Col>
			</Row>
		</>
	);
};

export default MonthlySummaries;
