import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { BsCalendar2Date } from "react-icons/bs";
import DateRangePicker from "react-bootstrap-daterangepicker";
import AllGamesGGR from "../../components/Charts/CasinoGameReports/AllGamesGGR";
import GGRByGameAllCasinos from "../../components/Charts/Finance/GGRByGameAllCasinos";
import AllGameTypeGGR from "../../components/Charts/Finance/AllGameTypeGGR";
import SlotGamesGGR from "../../components/GameReports/SlotGamesGGR";
import SlotsGGRChart from "../../components/GameReports/SlotsGGRChart";
import { Col, Row } from "react-bootstrap";
import GameThemeChart from "../../components/GameReports/GameThemeChart";
import GameTypeGGRChart from "../../components/GameReports/GameTypeGGRChart";
import GameBrandsGGRChart from "../../components/GameReports/GameBrandsGGRChart";
import BrandsGGRbyGameChart from "../../components/GameReports/BrandsGGRbyGameChart";

moment.locale("utc");

const GameReports = () => {
	const [currencies, setCurrencies] = useState([]);
	const [refreshToggle, setRefreshToggle] = useState(false);
	const [width, setWidth] = useState();
	const [state, setState] = useState({
		start: moment().startOf("month").toDate(),
		end: moment().endOf("month").toDate()
	});
	const { start, end } = state;

	const handleCallback = (start, end) => {
		setState({ start, end });
		if (start && end !== null) {
			setRefreshToggle((prev) => !prev);
		}
	};

	// render currency list
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_DOMAIN}/currencies/list?token=${JSON.parse(localStorage.getItem("currentCasino"))?.casino_token}`)
			.then((response) => {
				if (response?.data?.status === 200) {
					setCurrencies([{ currency_abrv: "All" }, ...response?.data?.data]);
				}
			})
			.catch(function (error) {
				if (error.response) {
					toast.error(error.response?.data?.message, {
						theme: "dark",
						autoClose: 1500
					});
				} else {
					toast.error(error.message, { theme: "dark", autoClose: 1500 });
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setWidth(window.innerWidth);
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<>
			<div className="form_box_wp casino_chart mb_30">
				<div className="datepicker_wp mb_30">
					<div className="row">
						<div className="col-lg-4">
							<div className="date_picker_wrapper">
								<label className="form_input_label">
									<div className="form_input_icon">
										<BsCalendar2Date size={20} />
									</div>
									Select Date
								</label>

								<DateRangePicker
									initialSettings={{
										startDate: moment().startOf("month").toDate(),
										endDate: moment().endOf("month").toDate(),
										ranges: {
											Today: [moment().toDate(), moment().toDate()],
											Yesterday: [moment().subtract(1, "days").toDate(), moment().subtract(1, "days").toDate()],
											"Last 7 Days": [moment().subtract(6, "days").toDate(), moment().toDate()],
											"Last 30 Days": [moment().subtract(29, "days").toDate(), moment().toDate()],
											"This Month": [moment().startOf("month").toDate(), moment().endOf("month").toDate()],
											"Last Month": [moment().subtract(1, "month").startOf("month").toDate(), moment().subtract(1, "month").endOf("month").toDate()]
										}
									}}
									onCallback={handleCallback}
								>
									<input
										type="text"
										className="form_input"
									/>
								</DateRangePicker>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="table_loader_wp casino_chart form_box_wp mb_30">
				<AllGameTypeGGR
					startDate={moment(start).format("YYYY-MM-DD")}
					endDate={moment(end).format("YYYY-MM-DD")}
					currencies={currencies}
					refreshToggle={refreshToggle}
					width={width}
				/>
			</div>
			<div className="table_loader_wp casino_chart form_box_wp mb_30">
				<AllGamesGGR
					startDate={moment(start).format("YYYY-MM-DD")}
					endDate={moment(end).format("YYYY-MM-DD")}
					currencies={currencies}
					refreshToggle={refreshToggle}
					width={width}
				/>
			</div>
			{/* <div className="table_loader_wp casino_chart form_box_wp mb_30">
				<GGRByGameAllCasinos
					startDate={moment(start).format("YYYY-MM-DD")}
					endDate={moment(end).format("YYYY-MM-DD")}
					currencies={currencies}
					refreshToggle={refreshToggle}
					width={width}
				/>
			</div> */}
			<Row>
				{/* `<Col lg={12}>
					<div className="table_loader_wp casino_chart form_box_wp mb_30">
						<SlotGamesGGR
							startDate={moment(start).format("YYYY-MM-DD")}
							endDate={moment(end).format("YYYY-MM-DD")}
							currencies={currencies}
							refreshToggle={refreshToggle}
							width={width}
						/>
					</div>
				</Col>` */}
				{/* <Col
					lg={6}
					className="mb_30"
				>
					<div className="player_chart casino_chart form_box_wp table_loader_wp mb_30 h-100">
						<SlotsGGRChart
							startDate={moment(start).format("YYYY-MM-DD")}
							endDate={moment(end).format("YYYY-MM-DD")}
							// currency={selectedCurrency}
							currencies={currencies}
							refreshToggle={refreshToggle}
							width={width}
						/>
					</div>
				</Col> */}
				<Col lg={6}>
					<div className="player_chart casino_chart form_box_wp table_loader_wp">
						<GameTypeGGRChart
							startDate={moment(start).format("YYYY-MM-DD")}
							endDate={moment(end).format("YYYY-MM-DD")}
							// currency={selectedCurrency}
							currencies={currencies}
							refreshToggle={refreshToggle}
							width={width}
						/>
					</div>
				</Col>
				<Col
					lg={6}
					className="mb_30"
				>
					<div className="player_chart casino_chart form_box_wp table_loader_wp mb_30 h-100">
						<GameThemeChart
							startDate={moment(start).format("YYYY-MM-DD")}
							endDate={moment(end).format("YYYY-MM-DD")}
							// currency={selectedCurrency}
							currencies={currencies}
							refreshToggle={refreshToggle}
							width={width}
						/>
					</div>
				</Col>
				<Col lg={6}>
					<div className="player_chart casino_chart form_box_wp table_loader_wp">
						<GameBrandsGGRChart
							startDate={moment(start).format("YYYY-MM-DD")}
							endDate={moment(end).format("YYYY-MM-DD")}
							// currency={selectedCurrency}
							currencies={currencies}
							refreshToggle={refreshToggle}
							width={width}
						/>
					</div>
				</Col>
				<Col lg={6}>
					<div className="player_chart casino_chart form_box_wp table_loader_wp">
						<BrandsGGRbyGameChart
							startDate={moment(start).format("YYYY-MM-DD")}
							endDate={moment(end).format("YYYY-MM-DD")}
							// currency={selectedCurrency}
							currencies={currencies}
							refreshToggle={refreshToggle}
							width={width}
						/>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default GameReports;
