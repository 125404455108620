import axios from "axios";
import { useState } from "react";
import Loader from "../../Loader";
import { Col, Row } from "react-bootstrap";
import { AiOutlineExport } from "react-icons/ai";

const ExcelJS = require("exceljs");

const DetailedGameReports = ({ selectedGame, startDate, endDate, currency, accessType }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const downloadExcel = async (data) => {
        if (data.length === 0) {
            setError("There are no records to download");
            return;
        }
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Detailed Game Bets Report");

        sheet.getRow(3).values = [
            // "Transaction ID",
            // "Player ID",
            "Game ID",
            // "Action Type",
            "Debit",
            "Credit",
            "Date",
            "Time",
        ];

        sheet.columns = [
            // {
            //     header: "Transaction ID",
            //     key: "id",
            //     width: 15,
            // },
            // {
            //     header: "Player ID",
            //     key: "player_id",
            //     width: 10,
            // },
            {
                header: "game_id",
                key: "game_id",
                width: 10,
            },
            // {
            //     header: "Action Type",
            //     key: "action_type",
            //     width: 12,
            // },
            {
                header: "Debit",
                key: "debit",
                width: 10,
            },
            {
                header: "Credit",
                key: "credit",
                width: 10,
            },
            {
                header: "Date",
                key: "date",
                width: 12,
            },
            {
                header: "Time",
                key: "time",
                width: 12,
            },
        ];

        const firstRow = sheet.getRow(3);
        firstRow.eachCell((cell) => {
            sheet.getCell(cell.address).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "00AAAA" },
            };
        });

        sheet.getRow(3).font = {
            bold: true,
        };

        sheet.getRow(1).font = {
            bold: true,
        };

        const transactionsRange =
            startDate === endDate ? `of ${startDate}` : `between ${startDate} - ${endDate}`;

        sheet.mergeCells("A1", "H1");
        sheet.getCell("A1").value = `Game Bets Transactions ${transactionsRange}`;
        sheet.getRow(1).alignment = { vertical: "middle", horizontal: "center" };

        data.map((data, index) => {
            const dateTime = new Date(data.created_at).toLocaleString(undefined, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                hour12: false,
                minute: "2-digit",
                second: "2-digit",
            });
            sheet.addRow({
                ...data,
                date: dateTime.slice(0, 10),
                time: dateTime.slice(-8),
            });
        });

        await workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });

            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "download.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    };

    /**
     * Export handler
     */
    const exportDetailedGameReportsHandler = async () => {
        setLoading(true);
        setError("");

        const casino = JSON.parse(localStorage?.getItem("currentCasino"));
        const game = !selectedGame ? "" : selectedGame?.id === 0 ? "" : selectedGame?.id;
        const allCasinos = JSON.parse(localStorage.getItem("userInformation"));

        const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/games/reports/each-game-transactions?token=${casino?.casino_token}&casino=${casino?.casino_key}&currency=${currency}&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z&game=${game}&action=game&accessType=${accessType}`;

        await axios.post(apiUrl, {
            casinos: allCasinos?.casino_information || []
        }).then((response) => {
            if (response.data?.status === 200) {
                downloadExcel(response.data?.data);
            } else {
                setError(response.data?.message);
            }
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Row className="align-items-center row-gap-2">
            <Col lg={6}>
                <h6>Detailed Game Report</h6>
            </Col>
            <Col lg={6}>
                <div className="button_group justify-content-end">
                    <button
                        type="submit"
                        className="sec_btn"
                        onClick={exportDetailedGameReportsHandler}
                        disabled={loading}>
                        Download Excel <AiOutlineExport />
                    </button>
                </div>

                {loading ? <Loader /> : error ? <p>{error}</p> : ""}
            </Col>
        </Row>
    );
};

export default DetailedGameReports;
