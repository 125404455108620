import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import CurrenciesDropdown from "../Common/CurrenciesDropdown";
import Loader from "../Loader";
import { GiCreditsCurrency } from "react-icons/gi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import axios from "axios";
import DropDown from "../Common/DropDown";

createTheme(
    "solarized",
    {
        text: {
            primary: "#ffffff",
            secondary: "#8651d6",
        },
        background: {
            default: "rgb(22 22 22 / 20%)",
        },
        context: {
            background: "#cb4b16",
            text: "#FFFFFF",
        },
        divider: {
            default: "rgba(225,225,225,0.08)",
        },
    },
    "dark"
);

const SlotGamesGGR = ({ startDate, endDate, currencies, refreshToggle, width }) => {
    const [loading, setLoading] = useState(false);
    const [currency, setCurrency] = useState("All");
    const [errorMessage, setErrorMessage] = useState("");

    const [slotGamesGGRData, setSlotGamesGGRData] = useState([]);
    const [slotDropDown, setSlotDropdown] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState("All");
    const [responseCurrency, setResponseCurrency] = useState();

    const getData = async (sortBy = '', orderBy = 'asc') => {
        setLoading(true);

        const casino = JSON.parse(localStorage?.getItem("currentCasino"));

        await axios
            .get(
                `${process.env.REACT_APP_API_DOMAIN}/reports/transactions/dashboard/ggr-slots-games?token=${casino?.casino_token}&casino=${casino?.casino_key}&currency=${currency === "All" ? "" : currency
                }&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z&filterBy=${selectedSlot === "All" ? "" : selectedSlot}&sortBy=${sortBy}&orderBy=${orderBy}`
            )
            .then((response) => {
                if (response.data?.status === 200) {
                    setSlotGamesGGRData(response.data?.reportBySlots);
                    setResponseCurrency(response.data?.currency);
                }
            })
            .catch((error) => {
                setErrorMessage(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshToggle, selectedSlot, currency]);

    const customSort = (rowA, rowB, sort) => {
        if (rowA[sort] > rowB[sort]) return 1;
        if (rowB[sort] > rowA[sort]) return -1;
        return 0;
    }

    const columns = [
        {
            name: "Game",
            selector: (row) => row?.game,
            sortable: true
        },
        {
            name: "Number of Txns",
            selector: (row) => row?.numberOfTxns,
            sortable: true
        },
        {
            name: "GGR",
            selector: (row) => `${Number(row?.GGR).toFixed(2)} ${responseCurrency}`,
            sortable: true,
            sortFunction: (rowA, rowB) => customSort(rowA, rowB, 'GGR')
        },
        {
            name: "Slot Type",
            selector: (row) => row?.gameSize,
            sortable: true
        }
    ];

    return (
        <div>
            <div className="finance_heapchart mb_30">
                <Row className="align-items-center">
                    <Col lg={4}>
                        <h5 className="h5_title">Slot Games GGR</h5>
                    </Col>
                    <Col lg={2}></Col>
                    <Col lg={3}>
                        <div className="datepicker_right">
                            <label className="form_input_label">
                                <div className="form_input_icon">
                                    <GiCreditsCurrency size={20} />
                                </div>
                                Slots
                            </label>
                            <DropDown
                                placeholder="Select Currency"
                                options={['All', '3x5', '4x5', '5x5']}
                                dataKey=""
                                isSelected={selectedSlot}
                                activeOption={selectedSlot}
                                activeOptionId={selectedSlot}
                                setOption={(data, index) => {
                                    setSelectedSlot(data);
                                }}
                            />
                            {/* <div
                                className={`dropdown_wp currency_dropdown ${slotDropDown ? "active_dropdown" : ""
                                    }`}>
                                <button
                                    className="sec_btn dropdown_btn transparent_btn"
                                    onClick={() => width <= 991 && setSlotDropdown((prev) => !prev)}
                                    onMouseEnter={() => width >= 991 && setSlotDropdown(true)}
                                    onMouseLeave={() => width >= 991 && setSlotDropdown(false)}>
                                    {selectedSlot}
                                    <span className="dropdown_icon">
                                        <MdOutlineKeyboardArrowDown size={22} />
                                    </span>
                                </button>

                                <ul className="dropdown_list">
                                    <li onClick={() => setSelectedSlot("All")}>All</li>
                                    <li onClick={() => setSelectedSlot("3x5")}>3x5</li>
                                    <li onClick={() => setSelectedSlot("4x5")}>4x5</li>
                                    <li onClick={() => setSelectedSlot("5x5")}>5x5</li>
                                </ul>
                            </div> */}
                        </div>
                    </Col>
                    <Col lg={3}>
                        {/* <CurrenciesDropdown
                            currencies={currencies}
                            currency={currency}
                            setCurrency={setCurrency}
                            width={width}
                        /> */}
                        <div className="datepicker_right">
                            <label className="form_input_label">
                                <div className="form_input_icon">
                                    <GiCreditsCurrency size={20} />
                                </div>
                                Select Currency
                            </label>

                            <DropDown
                                placeholder="Select Currency"
                                options={currencies}
                                dataKey="currency_abrv"
                                isSelected={currency}
                                activeOption={currency}
                                activeOptionId={currency}
                                setOption={(data, index) => {
                                    setCurrency(data?.currency_abrv);
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="player_reports_table mb_30">
                <div className="table_loader_wp" style={{ minHeight: "70px" }}>
                    {errorMessage ? (
                        <p style={{ textAlign: "center", color: "red" }}>{errorMessage}</p>
                    ) : slotGamesGGRData.length < 0 || loading ? (
                        <Loader />
                    ) : (
                        <DataTable
                            theme="solarized"
                            columns={columns}
                            data={slotGamesGGRData}
                            progressPending={loading}
                            pagination
                            paginationRowsPerPageOptions={[10, 20, 50, 100]}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default SlotGamesGGR;
