import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import DataTable, { createTheme } from "react-data-table-component";
import Loader from "../../../components/Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { BsCalendar2Date } from "react-icons/bs";
import { GiCreditsCurrency } from "react-icons/gi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { toast } from "react-toastify";
import DropDown from "../../../components/Common/DropDown";
import InputField from "../../../components/Common/InputField";
import { Col } from "react-bootstrap";

const CasinoTable = () => {
	const [state, setState] = useState({
		start: moment().startOf("month").toDate(),
		end: moment().endOf("month").toDate()
	});
	const { start, end } = state;
	const handleCallback = (start, end) => {
		setState({ start, end });
	};
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [errorMessage, setErrorMessage] = useState("");
	const [limit, setlimit] = useState(10);
	const [sortingData, setSortingData] = useState({ field: "remote_id", order: "asc" });
	const [refreshToggle, setRefreshToggle] = useState(false);
	const [selectedCurrency, setSelectedCurrency] = useState("USD");
	const [currencies, setCurrencies] = useState([]);
	const [currentCurrency, setCurrentCurrency] = useState();
	const [dropDown, setDropdown] = useState(false);
	const [width, setWidth] = useState();
	const [search, setSearch] = useState("");

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_DOMAIN}/currencies/list?token=${JSON.parse(localStorage.getItem("currentCasino"))?.casino_token}`)
			.then((response) => {
				if (response?.data?.status === 200) {
					setCurrencies(response?.data?.data);
				}
			})
			.catch(function (error) {
				if (error.response) {
					toast.error(error.response?.data?.message, {
						theme: "dark",
						autoClose: 1500
					});
				} else {
					toast.error(error.message, { theme: "dark", autoClose: 1500 });
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//* Set Table Dark Theme  /
	createTheme(
		"solarized",
		{
			text: {
				primary: "#ffffff",
				secondary: "#8651d6"
			},
			background: {
				default: "rgb(22 22 22 / 20%)"
			},
			context: {
				background: "#cb4b16",
				text: "#FFFFFF"
			},
			divider: {
				default: "rgba(225,225,225,0.08)"
			}
		},
		"dark"
	);

	/* Set Table Column  */
	const columns = [
		{
			name: "Player",
			selector: (row) => row.remoteId || `${row.fname} ${row.lname}`,
			sortable: true
		},
		{
			name: "Registered",
			selector: (row) => moment(row.createdAt).format("YYYY-MM-DD"),
			sortable: true
		},
		{
			name: "Bet history",
			selector: (row) => row.transactionsCount,
			sortable: true
		},
		{
			name: "GGR",
			selector: (row) => `${(row?.returnValue).toFixed(2)} ${currentCurrency}`,
			sortable: true
		}
	];

	const handleSort = (column, sortDirection) => {
		const order = sortDirection === "asc" ? "desc" : "asc";
		let field = "";

		const keyFields = {
			Player: "remote_id",
			Registered: "created_at",
			Bethistory: "transactionsCount",
			GGR: "returnValue"
		};

		const columnKey = column.name.replace(" ", "");
		if (columnKey in keyFields) {
			field = keyFields[columnKey];

			const updatedSortOrder = {
				field: field,
				order: order
			};

			setSortingData(updatedSortOrder);
			fetchUsers(1, updatedSortOrder);
		}
	};

	const onSubmitCartData = (e) => {
		e.preventDefault();
		setErrorMessage("");

		setRefreshToggle((prev) => !prev);
	};

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setRefreshToggle((prev) => !prev);
		}, 500);
		return () => clearTimeout(timeoutId);
	}, [search]);

	/* Data Fetch Function   */
	const fetchUsers = async (page, newSortOrder) => {
		setLoading(true);

		const currentCasino = JSON.parse(localStorage?.getItem("currentCasino"));
		await axios
			.get(
				`${process.env.REACT_APP_API_DOMAIN}/reports/player/limited?token=${currentCasino?.casino_token}&casino=${currentCasino?.casino_key}&page=${page}&limit=${limit}&sortBy=${newSortOrder?.field}&orderBy=${
					newSortOrder?.order
				}&start=${moment(start).format("YYYY-MM-DD")}T00:00:00Z&end=${moment(end).format("YYYY-MM-DD")}T23:59:59Z&currency=${selectedCurrency}&searchRemoteId=${search}`
			)
			.then((response) => {
				if (response.data.status === 200) {
					setCurrentCurrency(response?.data?.response?.currency);
					setData(response.data?.response?.players);
					setTotalRows(response.data?.response?.totalCount?.total);
				} else {
					setErrorMessage(response?.data?.response);
				}
			})
			.catch((error) => {
				if (error.response) {
					setErrorMessage(error.response?.data?.message);
				} else {
					setErrorMessage(error.message);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handlePerRowsChange = async (limit, page) => {
		setlimit(limit);
		const currentCasino = JSON.parse(localStorage?.getItem("currentCasino"));
		const startDate = moment(start).format("YYYY-MM-DD");
		const endDate = moment(end).format("YYYY-MM-DD");

		await axios
			.get(
				`${process.env.REACT_APP_API_DOMAIN}/reports/player/limited?token=${currentCasino?.casino_token}&casino=${currentCasino?.casino_key}&page=${page}&limit=${limit}&sortBy=${sortingData?.field}&orderBy=${sortingData?.order}&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z`
			)
			.then((response) => {
				if (response.data.status === 200) {
					setData(response.data?.response?.players);
					setTotalRows(response.data?.response?.totalCount?.total);
				} else {
					setErrorMessage(response?.data?.response);
				}
			})
			.catch((error) => {
				if (error.response.data.error.status === 403) {
					setErrorMessage(error.response.data.error.message);
				} else if (error?.message === "Request failed with status code 403") {
					setErrorMessage("Unauthorized Toekn");
				} else {
					setErrorMessage(error?.message);
				}
			})
			.finally();
	};

	const handlePageChange = (page) => {
		fetchUsers(page, sortingData);
	};

	useEffect(() => {
		fetchUsers(1, sortingData); // fetch page 1 of users
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshToggle]);

	const selectCurrency = (currency) => {
		setSelectedCurrency(currency);
		setDropdown(false);
	};

	useEffect(() => {
		setWidth(window.innerWidth);
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const FavoriteGame = ({ data }) => {
		return (
			<div className="favorite_games">
				<h5>Favorite Game</h5>
				<ul>
					{Object.keys(data?.favoriteGames)
						?.slice(0, 5)
						?.map((i, index) => {
							return (
								<li>
									<span>#{index + 1}</span> {i}
								</li>
							);
						})}
				</ul>
			</div>
		);
	};

	return (
		<>
			<div className="form_box_wp mb_30">
				<div className="player_reports_table">
					<div
						className="table_loader_wp"
						style={{ minHeight: "90px" }}
					>
						<form onSubmit={onSubmitCartData}>
							<div className="row">
								<div className="col-lg-4">
									<div className="form_input_wp">
										<label className="form_input_label">
											<div className="form_input_icon">
												<BsCalendar2Date size={20} />
											</div>
											Select Date
										</label>

										<DateRangePicker
											initialSettings={{
												startDate: moment().startOf("month").toDate(),
												endDate: moment().endOf("month").toDate(),
												ranges: {
													Today: [moment().toDate(), moment().toDate()],
													Yesterday: [moment().subtract(1, "days").toDate(), moment().subtract(1, "days").toDate()],
													"Last 7 Days": [moment().subtract(6, "days").toDate(), moment().toDate()],
													"Last 30 Days": [moment().subtract(29, "days").toDate(), moment().toDate()],
													"This Month": [moment().startOf("month").toDate(), moment().endOf("month").toDate()],
													"Last Month": [moment().subtract(1, "month").startOf("month").toDate(), moment().subtract(1, "month").endOf("month").toDate()]
												}
											}}
											onCallback={handleCallback}
										>
											<input
												type="text"
												className="form_input"
											/>
										</DateRangePicker>
									</div>
								</div>
								<div className="col-lg-3">
									<div className="form_input_wp">
										<label className="form_input_label">
											<div className="form_input_icon">
												<GiCreditsCurrency size={20} />
											</div>
											Select Currency
										</label>
										<DropDown
											placeholder="Select Month"
											options={currencies}
											dataKey="currency_abrv"
											isSelected={selectedCurrency}
											activeOption={selectedCurrency}
											activeOptionId={selectedCurrency}
											setOption={(data, index) => {
												setSelectedCurrency(data?.currency_abrv);
											}}
										/>
										{/* <div
                                            className={`dropdown_wp currency_dropdown ${
                                                dropDown ? "active_dropdown" : ""
                                            }`}>
                                            <button
                                                className="sec_btn dropdown_btn transparent_btn"
                                                onClick={() => width <= 991 && setDropdown((prev) => !prev)}
                                                onMouseEnter={() => width >= 991 && setDropdown(true)}
                                                onMouseLeave={() => width >= 991 && setDropdown(false)}>
                                                {selectedCurrency ? selectedCurrency : ""}
                                                <span className="dropdown_icon">
                                                    <MdOutlineKeyboardArrowDown size={22} />
                                                </span>
                                            </button>

                                            <ul className="dropdown_list">
                                                {currencies?.map((currency) => (
                                                    <li
                                                        key={currency.currency_abrv}
                                                        onClick={() => {
                                                            selectCurrency(currency.currency_abrv);
                                                        }}>
                                                        {currency?.currency_abrv}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div> */}
									</div>
								</div>
								<div className="col-lg-3">
									<button
										type="submit"
										className="sec_btn"
									>
										Submit
									</button>
								</div>
							</div>
						</form>

						<h5 className="h5_title text-center mb-3 mt_30">Players History</h5>
						<Col lg={4}>
											<InputField
												type="search"
												value={search}
												onChange={(e) => setSearch(e.target.value)}
												placeholder="Search by player remote ID"
											/>
										</Col>
						{errorMessage ? (
							<p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>
						) : (
							<>
								{data.length > 0 && (
									<>
										<DataTable
											theme="solarized"
											columns={columns}
											data={data}
											pagination
											paginationServer
											expandableRows
											expandableRowsComponent={FavoriteGame}
											paginationTotalRows={totalRows}
											onChangePage={handlePageChange}
											onChangeRowsPerPage={handlePerRowsChange}
											paginationRowsPerPageOptions={[10, 20, 50, 100]}
											selectAllRowsItem={true}
											onSort={handleSort}
											sortServer
										/>
									</>
								)}
							</>
						)}
						{loading ? <Loader /> : ""}
					</div>
				</div>
			</div>
		</>
	);
};

export default CasinoTable;
