import { UserInfoState } from "../../context/UserInformationContext";
import TransactionPerCasinoTable from "./TrasactionPerCasinoTable";

const TransactionsPerCasino = () => {
	return (
		<>
			<TransactionPerCasinoTable />
		</>
	);
};

export default TransactionsPerCasino;
