import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import Loader from "../../Loader";
import { Col, Row } from "react-bootstrap";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { GiCreditsCurrency } from "react-icons/gi";
import CurrenciesDropdown from "../../Common/CurrenciesDropdown";
import DropDown from "../../Common/DropDown";
const ExcelJS = require("exceljs");

const defaultGameType = "All";

const GameTypeBetsPerHourChart = ({ startDate, endDate, currencies, refreshToggle, width }) => {
    const [chartdata, setChartdata] = useState([]);




    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [chartHeight, setChartHeight] = useState("500px");
    const [allGameTypes, setAllGameTypes] = useState([]);
    const [selectedGameType, setSelectedGameType] = useState(defaultGameType);
    const [currency, setCurrency] = useState("All");
    const [betsRange, setBetsRange] = useState({ min: 0, max: 0, currency: "USD" });
    const [dataToExport, setDataToExport] = useState([]);
    const [exportLoading, setExportLoading] = useState(false);
    const [gameDropDown, setGameDropdown] = useState(false);

    useEffect(() => {
        const casino = JSON.parse(localStorage?.getItem("currentCasino"));
        axios
            .get(`${process.env.REACT_APP_API_DOMAIN}/games/gametypes/list?token=${casino?.casino_token}`)
            .then((response) => {
                setAllGameTypes([defaultGameType, ...response.data?.data]);
            });
    }, []);

    const getData = async () => {
        setErrorMessage("");
        const casino = JSON.parse(localStorage?.getItem("currentCasino"));
        // const taxRate = casino?.tax_rate.replace(/%/g, "");
        const gameType = !selectedGameType || selectedGameType === "All" ? "" : selectedGameType;

        setDataToExport([]);
        setLoading(true);

        await axios
            .get(
                `${process.env.REACT_APP_API_DOMAIN}/games/reports/game-bets-metrics?token=${casino?.casino_token
                }&currency=${currency === "All" ? "" : currency
                }&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z&gameType=${gameType}&action=gameType`
            )
            .then((response) => {
                if (response.data.status === 200) {
                    // let dayBetsRange = [];
                    let chartList = response.data?.data?.byHours;
                    if (chartList.length) {
                        chartList[0].currency = response.data.data.currency;
                        chartList[0].data[0].currency = response.data.data.currency;
                    }

                    const dataInExcelFormat = chartList.map((day, dIndex) => {
                        return day.data.map((hour, hIndex) => {
                            const nextHours = +hour?.x.slice(0, 2) + 2;

                            return {
                                day: hIndex === 0 ? day?.name : "",
                                hours: `${hour.x} to ${nextHours?.toString().padStart(2, "0") + ":00"}`,
                                betsPerHour: `${hour?.y.toFixed(2)}`,
                                numberOfBetsPerHour: hour?.numberOfBetsPerHour,
                                numberOfBetsPerDay: hIndex === 0 ? day?.numberOfBetsPerDay : "",
                            };
                        });
                    });

                    if (dataInExcelFormat?.length === 0) {
                        setErrorMessage("There are no records to display");
                    }

                    setBetsRange({
                        min: response.data?.data?.min,
                        max: response.data?.data?.max,
                        currency: currency === "All" ? "" : currency,
                    });
                    setChartHeight(response.data?.data?.byHours?.length * 30 + 50);

                    setChartdata(chartList);
                    setDataToExport(dataInExcelFormat.flat(1));
                } else {
                    setDataToExport([]);
                    setChartdata([]);
                    setErrorMessage(response?.data?.message);
                }
            })
            .catch((error) => {
                setChartdata([]);
                setDataToExport([]);
                setErrorMessage(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGameType, currency, refreshToggle]);

    const exportExcelHandler = async () => {
        setExportLoading(true);
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Report");

        sheet.getRow(3).values = ["Date", "No. of Bets (Day)", "Hours", "Bets (Hour)", "No. of Bets (Hour)"];

        sheet.columns = [
            {
                header: "Day",
                key: "day",
                width: 15,
            },
            {
                header: "No. of Bets (Day)",
                key: "numberOfBetsPerDay",
                width: 16,
            },
            {
                header: "Hours",
                key: "hours",
                width: 15,
            },
            {
                header: "Bets (Hour)",
                key: "betsPerHour",
                width: 12,
            },
            {
                header: "No. of Bets (Hour)",
                key: "numberOfBetsPerHour",
                width: 18,
            },
        ];

        const firstRow = sheet.getRow(3);
        firstRow.eachCell((cell) => {
            sheet.getCell(cell.address).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "00AAAA" },
            };
        });

        sheet.getRow(3).font = {
            bold: true,
        };

        sheet.getRow(1).font = {
            bold: true,
        };

        sheet.mergeCells("A1", "E1");
        sheet.getCell(
            "A1"
        ).value = `${selectedGameType} Game Type Bets Transactions in Hours with Number of Bets and Bets Amount`;
        sheet.getRow(1).alignment = { vertical: "middle", horizontal: "center" };

        dataToExport.forEach((data) => {
            sheet.addRow({
                day: data.day,
                numberOfBetsPerDay: data.numberOfBetsPerDay,
                hours: data.hours,
                betsPerHour: data.betsPerHour,
                numberOfBetsPerHour: data.numberOfBetsPerHour,
            });
        });

        await workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });

            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "download.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
        });

        setExportLoading(false);
    };

    const playerChartoption = {
        series: chartdata,
        options: {
            chart: {
                foreColor: "#ccc",
                toolbar: {
                    show: false,
                },
            },
            stroke: {
                width: 1,
                colors: ["rgba(149, 149, 149, 0.5)"],
            },
            dataLabels: {
                enabled: false,
            },
            yaxis: [
                {
                    opposite: false,
                    min: (min) => {
                        return betsRange.min;
                    },
                    max: (max) => {
                        return betsRange.max;
                    },
                },
            ],
            tooltip: {
                theme: "dark",
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                    const dayData = w.globals.initialSeries[seriesIndex];
                    const firstData = w.globals.initialSeries[0].data[0];
                    const nextHours = +data.x.slice(0, 2) + 2;

                    return `<div class='game_bets_tooltip'>
                        <p>Date: ${dayData.name}</p>
						<p>Time: ${data.x}-${nextHours.toString().padStart(2, "0") + ":00"}</p>
						<p class='game_bets_tooltip_total_bets'>Number of Bets: <b>${data.numberOfBetsPerHour}</b></p>
						<p class='game_bets_tooltip_total_bets'>Total Bets: <b>${data.y?.toFixed(2)} ${firstData?.currency}</b></p>
					</div>`;
                },
            },
            grid: {
                borderColor: "#535A6C",
                xaxis: {
                    lines: {
                        show: true,
                    },
                },
                row: {
                    colors: ["#8651d6"],
                    opacity: 0.2,
                },
                column: {
                    colors: ["#8651d6"],
                    opacity: 0.1,
                },
            },
            theme: {
                mode: "dark",
                palette: "palette10",
                monochrome: {
                    enabled: true,
                    color: "#8651d6",
                    shadeTo: "dark",
                    shadeIntensity: 0.01,
                },
            },
        },
    };

    return (
        <>
            <div className="finance_heapchart mb_30">
                <Row className="align-items-center">
                    <Col lg={3}>
                        <h5 className="h5_title">Game Type Bets (in Hours)</h5>
                    </Col>
                    <Col lg={3}>
                        <div className="text-lg-end">
                            <button
                                type="submit"
                                className="sec_btn"
                                style={{ marginTop: 34 }}
                                onClick={exportExcelHandler}
                                disabled={dataToExport.length === 0 || exportLoading ? true : false}>
                                Export to Excel
                            </button>
                        </div>
                    </Col>
                    <Col lg={3}>
                        {/* <CurrenciesDropdown
                            currencies={currencies}
                            currency={currency}
                            setCurrency={setCurrency}
                            width={width}
                        /> */}
                        <label className="form_input_label">
                            <div className="form_input_icon">
                                <GiCreditsCurrency size={20} />
                            </div>
                            Select Currency
                        </label>

                        <DropDown
                            placeholder="Select Currency"
                            options={currencies}
                            dataKey="currency_abrv"
                            isSelected={currency}
                            activeOption={currency}
                            activeOptionId={currency}
                            setOption={(data, index) => {
                                setCurrency(data?.currency_abrv);
                            }}
                        />
                    </Col>
                    <Col lg={3}>
                        <div className="datepicker_right">
                            <label className="form_input_label">
                                <div className="form_input_icon">
                                    <GiCreditsCurrency size={20} />
                                </div>
                                Select Game Type
                            </label>
                            <DropDown
                                placeholder="Select Currency"
                                options={allGameTypes}
                                dataKey=""
                                isSelected={selectedGameType}
                                activeOption={selectedGameType}
                                activeOptionId={selectedGameType}
                                setOption={(data, index) => {
                                    setSelectedGameType(data);
                                }}
                            />
                            {/* <div
                                className={`dropdown_wp currency_dropdown ${gameDropDown ? "active_dropdown" : ""
                                    }`}>
                                <button
                                    className="sec_btn dropdown_btn transparent_btn"
                                    onClick={() => width <= 991 && setGameDropdown((prev) => !prev)}
                                    onMouseEnter={() => width >= 991 && setGameDropdown(true)}
                                    onMouseLeave={() => width >= 991 && setGameDropdown(false)}>
                                    {selectedGameType}
                                    <span className="dropdown_icon">
                                        <MdOutlineKeyboardArrowDown size={22} />
                                    </span>
                                </button>

                                <ul className="dropdown_list">
                                    {allGameTypes?.map((gameType) => (
                                        <li
                                            key={gameType}
                                            onClick={() => {
                                                setSelectedGameType(gameType);
                                            }}>
                                            {gameType}
                                        </li>
                                    ))}
                                </ul>
                            </div> */}
                        </div>
                    </Col>
                </Row>
            </div>
            {errorMessage ? (
                <p style={{ textAlign: "center", color: "red" }}> {errorMessage}</p>
            ) : (
                <Chart {...playerChartoption} type="heatmap" width="100%" height={chartHeight + "px"} />
            )}
            {loading ? <Loader /> : ""}
        </>
    );
};

export default GameTypeBetsPerHourChart;
