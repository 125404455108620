import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import DataTable, { createTheme } from "react-data-table-component";
import Loader from "../../../components/Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { BsCalendar2Date } from "react-icons/bs";
import { LuUser2 } from "react-icons/lu";
import InputField from "../../../components/Common/InputField";

const TransactionPerCasinoTable = () => {
	const [state, setState] = useState({
		start: moment().startOf("month").toDate(),
		end: moment().endOf("month").toDate()
	});

	const [searchBoxValue, setSearchBoxValue] = useState("");
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [limit, setLimit] = useState(10);
	const [refreshToggle, setRefreshToggle] = useState(false);
	const [totalRows, setTotalRows] = useState(0);

	const { start, end } = state;
	const handleCallback = (start, end) => {
		setState({ start, end });
	};

	//* Set Table Dark Theme  /
	createTheme(
		"solarized",
		{
			text: {
				primary: "#ffffff",
				secondary: "#8651d6"
			},
			background: {
				default: "rgb(22 22 22 / 20%)"
			},
			context: {
				background: "#cb4b16",
				text: "#FFFFFF"
			},
			divider: {
				default: "rgba(225,225,225,0.08)"
			},
			pagination: {
				fontSize: "1rem",
				fontFamily: "inherit",
				textAlign: "right"
			}
		},
		"dark"
	);

	/* Set Table Column  */
	const columns = [
		{
			name: "Player",
			selector: (row) => row.remoteId || "-",
			sortable: true
		},
		{
			name: "Created At",
			selector: (row) => moment(row.created_at).format("YYYY-MM-DD HH:mm:ss") || "",
			sortable: true
		},
		{
			name: "RoundId",
			selector: (row) => row.roundId,
			sortable: true
		},
		{
			name: "Amount",
			selector: (row) => parseFloat(row.amount)?.toFixed(2),
			sortable: true
		},
		{
			name: "Currency",
			selector: (row) => (row.currency ? row.currency : "-"),
			sortable: true
		},
		{
			name: "Action Type",
			selector: (row) => (row.actionType ? row.actionType : "-"),
			sortable: true
		},
		{
			name: "Game Name",
			selector: (row) => (row.gameName ? row.gameName : "-"),
			sortable: true
		}
	];

	const onSubmitCartData = (e) => {
		e.preventDefault();
		setRefreshToggle((prev) => !prev);
	};

	/* Data Fetch Function   */
	const fetchRounds = async (newPerPage, page) => {
		setLimit(newPerPage);
		setLoading(true);
		setErrorMessage("");

		const currentCasino = JSON.parse(localStorage?.getItem("currentCasino"));
		await axios
			.get(
				`${process.env.REACT_APP_API_DOMAIN}/reports/transactions?casino=${currentCasino?.casino_key}&start=${moment(start).format("YYYY-MM-DD")}T00:00:00Z&end=${moment(end).format("YYYY-MM-DD")}T23:59:59Z&token=${
					currentCasino?.casino_token
				}&page=${page}&limit=${newPerPage}&searchValue=${searchBoxValue}`
			)
			.then((response) => {
				if (response.data.status === 200) {
					if (response.data?.response?.transactions?.length === 0) {
						setErrorMessage("There are no record found");
					}
					setData(response.data?.response?.transactions);
					setTotalRows(response.data?.response?.count);
				} else {
					setErrorMessage(response?.data?.response?.message);
				}
			})
			.catch((error) => {
				if (error.response) {
					setErrorMessage(error.response?.data?.message);
				} else {
					setErrorMessage(error.message);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchRounds(limit, 1); // fetch page 1 of users
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshToggle, state]);

	const handlePageChange = (page) => {
		fetchRounds(limit, page);
	};

	const handlePerRowsChange = (limit, page) => {
		fetchRounds(limit, page);
	};

	return (
		<>
			<div className="form_box_wp mb_30">
				<div className="player_reports_table">
					<div
						className="table_loader_wp casino_game_rounds_wp"
						style={{ minHeight: "90px" }}
					>
						<form onSubmit={onSubmitCartData}>
							<div className="row">
								<div className="col-lg-4">
									<div className="form_input_wp">
										<label className="form_input_label">
											<div className="form_input_icon">
												<BsCalendar2Date size={20} />
											</div>
											Select Date
										</label>

										<DateRangePicker
											initialSettings={{
												startDate: moment().startOf("month").toDate(),
												endDate: moment().endOf("month").toDate(),
												ranges: {
													Today: [moment().toDate(), moment().toDate()],
													Yesterday: [moment().subtract(1, "days").toDate(), moment().subtract(1, "days").toDate()],
													"Last 7 Days": [moment().subtract(6, "days").toDate(), moment().toDate()],
													"Last 30 Days": [moment().subtract(29, "days").toDate(), moment().toDate()],
													"This Month": [moment().startOf("month").toDate(), moment().endOf("month").toDate()],
													"Last Month": [moment().subtract(1, "month").startOf("month").toDate(), moment().subtract(1, "month").endOf("month").toDate()]
												}
											}}
											onCallback={handleCallback}
										>
											<input
												type="text"
												className="form_input"
											/>
										</DateRangePicker>
									</div>
								</div>

								<div className="col-lg-4">
									<InputField
										type="text"
										label="Player Or RoundId"
										labelIcon={<LuUser2 size={20} />}
										placeholder="Enter Player or RoundId"
										name="search_box_value"
										onChange={(event) => setSearchBoxValue(event.target.value)}
										value={searchBoxValue || searchBoxValue}
									/>
								</div>

								<div className="col-lg-3">
									<button
										type="submit"
										className="sec_btn"
									>
										Submit
									</button>
								</div>
							</div>
						</form>

						<h5 className="h5_title text-center mb-3">Transactions</h5>
						{errorMessage ? (
							<p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>
						) : (
							<>
								{data.length > 0 && (
									<>
										<DataTable
											theme="solarized"
											columns={columns}
											data={data}
											pagination
											paginationServer
											paginationTotalRows={totalRows}
											onChangePage={handlePageChange}
											onChangeRowsPerPage={handlePerRowsChange}
											paginationRowsPerPageOptions={[10, 20, 50, 100]}
										/>
									</>
								)}
							</>
						)}
						{loading ? <Loader /> : ""}
					</div>
				</div>
			</div>
		</>
	);
};

export default TransactionPerCasinoTable;
