import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { FiUser } from "react-icons/fi";
import DataTable, { createTheme } from "react-data-table-component";
import Loader from "../../components/Loader";
import Form from "react-bootstrap/Form";
import axios from "axios";
import DropDown from "../../components/Common/DropDown";

export default function UserPermission() {
  const [popularDropDown, setPopularDropdown] = useState(false);
  const [activeUser, setActiveUser] = useState(-1);
  const [width, setWidth] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  // const [data, setData] = useState([]);

  useEffect(() => {
    setWidth(window.innerWidth);
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getUsers = async () => {
    const userId = JSON.parse(
      localStorage.getItem("user-authorization-token")
    )?.userid;
    await axios
      .post(
        `${process.env.REACT_APP_LOGIN_API_DOMAIN}/api/get-users.php`,
        { userid: userId },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          const sorted = response.data.data?.sort((a, b) =>
            a.user_login === b.user_login
              ? 0
              : a.user_login < b.user_login
              ? -1
              : 1
          );
          setUsers(sorted);
        }
      })
      .catch((error) => {
        // setErrorMessage(error?.message);
      })
      .finally(() => {
        // setGetUserLoading(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  /** Set dark theme in table  */
  createTheme(
    "solarized",
    {
      text: {
        primary: "#ffffff",
        secondary: "#8651d6",
      },
      background: {
        default: "rgb(22 22 22 / 20%)",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "rgba(225,225,225,0.08)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Page",
      selector: (row) => row.page,
      width: "300px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "100px",
    },
  ];

  const updateUserPermission = async (selector, isActive) => {
    let status = isActive;
    await axios
      .post(
        `${process.env.REACT_APP_LOGIN_API_DOMAIN}/api/update-user-permissions.php`,
        { userid: activeUser?.id, page: selector },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data.status === true || response.data.status === false) {
          setActiveUser((data) => {
            return {
              ...data,
              access_permissions: response.data.data,
            };
          });

          status = response.data.data?.[selector];

          setUsers((data) => {
            const userIndex = data.findIndex(
              (user) => user?.id === activeUser?.id
            );
            data[userIndex].access_permissions[selector] = status;
            return data;
          });
        }
      })
      .catch((error) => {
        // setErrorMessage(error?.message);
      })
      .finally(() => {
        // setGetUserLoading(false);
      });
    return status;
  };

  const checkbox = (selector) => {
    let isChecked = activeUser?.access_permissions?.[selector] || false;
    return (
      <Form.Check
        type="switch"
        checked={isChecked}
        onChange={async (e) => {
          const updatedValue = await updateUserPermission(
            selector,
            e.target.value
          );
          e.target.value = updatedValue;
        }}
      />
    );
  };

  const data = [
    {
      id: 1,
      page: "Invoices",
      action: checkbox("invoices"),
    },
    {
      id: 2,
      page: "Main Dashboard",
      action: checkbox("main_dashboard"),
    },
    {
      id: 3,
      page: "Casino Game Reports",
      action: checkbox("casino_game_reports"),
    },
    {
      id: 4,
      page: "Game Rounds",
      action: checkbox("game_rounds"),
    },
    {
      id: 5,
      page: "Player Reports",
      action: checkbox("player_reports"),
    },
    {
      id: 6,
      page: "Financial Statements",
      action: checkbox("financial_statements"),
    },
    {
      id: 7,
      page: "Casino Settings",
      action: checkbox("casino_settings"),
    },
    {
      id: 8,
      page: "2F Settings",
      action: checkbox("2fa_settings"),
    },
    {
      id: 9,
      page: "Finance (Admin Reporting)",
      action: checkbox("finance_admin_reporting"),
    },
    {
      id: 10,
      page: "Financial Reports (Admin Reporting)",
      action: checkbox("financial_reports_admin_reporting"),
    },
    {
      id: 11,
      page: "Game Reports (Admin Reporting)",
      action: checkbox("game_reports_admin_reporting"),
    },
    {
      id: 12,
      page: "User Permissions (Admin Reporting)",
      action: checkbox("user_permissions_admin_reporting"),
    },
  ];

  return (
    <>
      <section className="form_box_wp user_permission_sec">
        <Row className="mb_30">
          <Col lg={4}>
            <div className="user_select_box">
              <div className="datepicker_right">
                <label className="form_input_label">
                  <div className="form_input_icon">
                    <FiUser />
                  </div>
                  Select User
                </label>
                {/* <div className={`dropdown_wp currency_dropdown ${popularDropDown ? "active_dropdown" : ""}`}>
                                    <button
                                        className="sec_btn dropdown_btn transparent_btn"
                                        onClick={() => width <= 991 && setPopularDropdown((prev) => !prev)}
                                        onMouseEnter={() => width >= 991 && setPopularDropdown(true)}
                                        onMouseLeave={() => width >= 991 && setPopularDropdown(false)}
                                    >
                                        {activeUser === -1 ? "Select User" : `${activeUser.user_login} (#${activeUser.id})`}
                                        <span className="dropdown_icon">
                                            <MdOutlineKeyboardArrowDown size={22} />
                                        </span>
                                    </button>

                                    <ul className="dropdown_list">
                                        {users?.map((data, index) => (
                                            <li
                                                className={`${data.id === activeUser?.id ? "active-option" : ""}`}
                                                key={`User #${index}`}
                                                onClick={() => {
                                                    setActiveUser(data);
                                                }}
                                            >
                                                {`${data.user_login} (#${data.id})`}
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}

                <DropDown
                  placeholder="Select User"
                  options={users}
                  dataKey="user_login"
                  isSelected={activeUser}
                  activeOption={`${activeUser.user_login} (#${activeUser.id})`}
                  activeOptionId={activeUser?.user_login}
                  setOption={(data, index) => setActiveUser(data)}
                />
              </div>

              {activeUser !== -1 && (
                <div className="form_box_wp">
                  <h5 class="h5_title">User Information</h5>
                  <div class="divider mb_10"></div>
                  <div className="uer_permission_info_box">
                    <ul>
                      <li>
                        <b>User ID: </b> {activeUser?.id}
                      </li>
                      <li>
                        <b>Username: </b> {activeUser?.user_login}
                      </li>
                      <li>
                        <b>Email </b> {activeUser?.user_email}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </Col>

          <Col lg={8}>
            {activeUser !== -1 && (
              <>
                <div class="sec_wp mb_10">
                  <h4 class="h5_title">Page Permission</h4>
                </div>
                <div className="user_permission_table">
                  <div
                    className="table_loader_wp"
                    style={{ minHeight: "70px" }}
                  >
                    {errorMessage ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        {errorMessage}
                      </p>
                    ) : (
                      <>
                        {data.length > 0 && (
                          <DataTable
                            theme="solarized"
                            columns={columns}
                            data={data}
                          />
                        )}
                      </>
                    )}
                    {loading ? <Loader /> : ""}
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
      </section>
    </>
  );
}
