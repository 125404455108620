import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Loader from "../../Loader";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { GiCreditsCurrency } from "react-icons/gi";
import DropDown from "../../Common/DropDown";
import moment from "moment";

const defaultGamesObj = {
	games: {},
	active: -1,
	game: "",
	activeRow: -1,
	activeCol: -1,
	underSize: true
};
const chunkSize = 10;

const AllGameTypeGGR = ({ startDate, endDate, currencies, refreshToggle, width }) => {
	const [loading, setLoading] = useState(false);
	const [chartDate, setChartDate] = useState([]);
	const [xAxisList, setXAxisList] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");
	const [currency, setCurrency] = useState("All");
	const [activeTrend, setActiveTrend] = useState(-1);

	const [games, setGames] = useState({
		games: {},
		active: -1,
		game: "",
		activeRow: -1,
		activeCol: -1,
		underSize: true
	});

	const getDaysBetweenDates = (startDate, endDate) => {
		const start = moment(startDate);
		const end = moment(endDate);
		const difference = end.diff(start, "days");
		return difference;
	};

	const getData = () => {
		setErrorMessage("");

		setLoading(true);

		axios
			.get(
				`${process.env.REACT_APP_API_DOMAIN}/reports/games/transactions/dashboard/chart-admin?currency=${currency === "All" ? "" : currency}&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z&type=gameType&date=${
					getDaysBetweenDates(startDate, endDate) === 0 ? `${startDate}T00:00:00Z` : ""
				}`
			)
			.then((response) => {
				if (response.data.status === 200) {
					let gamedata = response.data?.response?.chartData;
					if (gamedata.length === 0) {
						setErrorMessage("There are no records to display");
					}
					let chunks = [];

					if (currency === "All") {
						gamedata = gamedata.map((row, index) => {
							return {
								...row,
								originalData: row.data,
								data: row.usdData
							};
						});
					}

					if (gamedata.length > chunkSize) {
						for (let i = 0; i < gamedata.length; i += chunkSize) {
							chunks.push(gamedata.slice(i, i + chunkSize));
						}
					} else {
						chunks = [gamedata];
					}

					setActiveTrend(0);
					setXAxisList(chunks);

					setGames((prev) => ({
						...prev,
						games: Array.isArray(response.data?.response?.gamesList?.games) ? response.data?.response?.gamesList?.games.sort() : [],
						active: -1
					}));

					setChartDate(response.data?.response?.daysOrMonths);
				} else {
					setActiveTrend(-1);
					setGames(defaultGamesObj);
					setChartDate([]);
					setXAxisList([]);

					setErrorMessage(response?.data?.message);
				}
			})
			.catch((error) => {
				setGames(defaultGamesObj);
				setActiveTrend(-1);
				setChartDate([]);
				setXAxisList([]);

				setErrorMessage(error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshToggle, currency]);

	const dashboardChartOptions = {
		chart: {
			height: 350,
			type: "line",
			zoom: {
				enabled: false
			},
			toolbar: {
				show: false
			}
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			width: 3,
			curve: "smooth"
		},
		title: {
			align: "center",
			style: {
				color: "#FEB019"
			}
		},
		xaxis: {
			categories: chartDate ? chartDate : [],
			labels: {
				style: {
					colors: "#ccc"
				}
			}
		},
		yaxis: [
			{
				labels: {
					formatter: (value) => {
						const currencyLabel = currency === "All" ? "(All in USD)" : currency;
						return `${parseFloat(value).toFixed(2)} ${currencyLabel}`;
					},
					style: {
						colors: "#ccc"
					}
				}
			}
		],
		tooltip: {
			theme: "dark",
			followCursor: true
		},
		legend: {
			position: "top",
			labels: {
				colors: "#fff"
			},
			markers: {
				offsetX: -4,
				offsetY: -1
			},
			itemMargin: {
				horizontal: 10
			}
		}
	};

	const series = xAxisList.length
		? activeTrend !== -1
			? xAxisList?.at(activeTrend).map((game) => ({ name: game.name, data: game.data }))
			: games?.active !== -1
			? !games?.underSize
				? [xAxisList[games?.activeRow][games?.activeCol]]
				: [{ name: games?.game, data: xAxisList[games?.active]?.data || [] }]
			: []
		: [];

	return (
		<>
			<div className="finance_heapchart mb_30">
				<Row className="align-items-center">
					{(activeTrend !== -1 || games?.active !== -1 ? 6 : 10) && (
						<Col lg={9}>
							<h5 className="h5_title">{`All Game Types GGR (${getDaysBetweenDates(startDate, endDate) === 0 ? "By Hours" : "By Days"})`}</h5>
						</Col>
					)}

					{/* {(activeTrend !== -1 || games?.active !== -1) && (
                        <Col lg={3}>
                            <div className="datepicker_right">
                                <label className="form_input_label">
                                    <div className="form_input_icon">
                                        <GiCreditsCurrency size={20} />
                                    </div>
                                    More Populars
                                </label>
                                <div
                                    className={`dropdown_wp currency_dropdown ${
                                        popularDropDown ? "active_dropdown" : ""
                                    }`}>
                                    <button
                                        className="sec_btn dropdown_btn transparent_btn"
                                        onClick={() => width <= 991 && setPopularDropdown((prev) => !prev)}
                                        onMouseEnter={() => width >= 991 && setPopularDropdown(true)}
                                        onMouseLeave={() => width >= 991 && setPopularDropdown(false)}>
                                        {activeTrend === -1 ? "Select List" : `Popular #${activeTrend}`}
                                        <span className="dropdown_icon">
                                            <MdOutlineKeyboardArrowDown size={22} />
                                        </span>
                                    </button>

                                    <ul className="dropdown_list">
                                        {xAxisList?.map((data, index) => (
                                            <li
                                                key={`Popular #${index}`}
                                                onClick={() => {
                                                    setActiveTrend(index);
                                                    setGames((prev) => {
                                                        return {
                                                            ...prev,
                                                            active: -1,
                                                            game: "",
                                                            activeRow: -1,
                                                            activeCol: -1,
                                                        };
                                                    });
                                                }}>
                                                {`Popular #${index}`}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    )}

                    {(activeTrend !== -1 || games?.active !== -1) && (
                        <Col lg={3}>
                            <div className="datepicker_right">
                                <label className="form_input_label">
                                    <div className="form_input_icon">
                                        <GiCreditsCurrency size={20} />
                                    </div>
                                    Games
                                </label>
                                <div
                                    className={`dropdown_wp currency_dropdown ${
                                        gameDropDown ? "active_dropdown" : ""
                                    }`}>
                                    <button
                                        className="sec_btn dropdown_btn transparent_btn"
                                        onClick={() => width <= 991 && setGameDropdown((prev) => !prev)}
                                        onMouseEnter={() => width >= 991 && setGameDropdown(true)}
                                        onMouseLeave={() => width >= 991 && setGameDropdown(false)}>
                                        {games?.active === -1 ? "Select Game" : games?.game}
                                        <span className="dropdown_icon">
                                            <MdOutlineKeyboardArrowDown size={22} />
                                        </span>
                                    </button>

                                    <ul className="dropdown_list">
                                        {games?.games?.map((data, index) => (
                                            <li
                                                key={index}
                                                onClick={() => {
                                                    if (games?.games?.length > chunkSize) {
                                                        let row = Math.floor(index / chunkSize);
                                                        let col = index % chunkSize;

                                                        if (index === 0) {
                                                            row = 0;
                                                            col = 0;
                                                        }

                                                        setGames((prev) => ({
                                                            ...prev,
                                                            active: index,
                                                            game: data,
                                                            activeRow: row,
                                                            activeCol: col,
                                                            underSize: false,
                                                        }));
                                                    } else {
                                                        setGames((prev) => ({
                                                            ...prev,
                                                            active: index,
                                                            game: data,
                                                            activeRow: -1,
                                                            activeCol: -1,
                                                            underSize: true,
                                                        }));
                                                    }
                                                    setActiveTrend(-1);
                                                }}>
                                                {data}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    )} */}

					<Col lg={3}>
						<div className="datepicker_right">
							<label className="form_input_label">
								<div className="form_input_icon">
									<GiCreditsCurrency size={20} />
								</div>
								Select Currency
							</label>

							<DropDown
								placeholder="Select Currency"
								options={currencies}
								dataKey="currency_abrv"
								isSelected={currency}
								activeOption={currency}
								activeOptionId={currency}
								setOption={(data, index) => {
									setCurrency(data?.currency_abrv);
								}}
							/>
						</div>
						{/* <CurrenciesDropdown currencies={currencies} currency={currency} setCurrency={setCurrency} width={width} /> */}
					</Col>
				</Row>
			</div>

			{errorMessage ? (
				<p style={{ textAlign: "center", color: "red" }}> {errorMessage}</p>
			) : (
				<Chart
					options={dashboardChartOptions}
					series={series}
					type="line"
					width="100%"
					height="600px"
				/>
			)}

			{loading ? <Loader /> : ""}
		</>
	);
};

export default AllGameTypeGGR;
