import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MdOutlineCasino, MdOutlineRemoveCircleOutline } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa6";
import { IoIosAdd } from "react-icons/io";
import DropDown from "../Common/DropDown";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../Loader";
import InputField from "../Common/InputField";
import { UserInfoState } from "../../context/UserInformationContext";
import Verify2fa from "../Common/Verify2fa";

const defaultCasinoData = {
  casino: "",
  casino_name: "",
  casino_url: "",
  casino_token: "",
  casino_key: "",
  casino_brand_id: "",
};

const actionResponseNames = {
  CREATE: "Added",
  UPDATE: "Updated",
  DELETE: "Deleted",
};

const CasinoTokenSetting = ({
  casinoInfo,
  brands,
  updateCasinos,
  currencies,
  isError,
}) => {
  const { userInfo } = UserInfoState();
  const [isAddCasino, setIsAddCasino] = useState(false);
  const [selectedCasino, setSelectedCasino] = useState(casinoInfo?.[0]);
  const [selectedBrand, setSelectedBrand] = useState();
  const [formData, setFormData] = useState(defaultCasinoData);
  const [isLoading, setIsLoading] = useState(false);
  const [twoFaSetting, setTwoFaSetting] = useState({
    verifiedOtp: false,
    isDisplayCancelButton: false,
    isClicked: false,
    action: "",
  });

  const brandIdentifier = (casino_brand_id) => {
    return brands.filter((brand) => brand?.brand_id === casino_brand_id);
  };

  useEffect(() => {
    if (!isError && !casinoInfo.length) {
      setIsAddCasino(true);
    }
  }, [isError]);

  // const removeFormFields = (i, element) => {
  //     const confirmation = window.confirm(
  //         `Are you sure you want to delete ${element?.casino_name} casino?`
  //     );
  //     if (confirmation) {
  //         setIsDisabled(false);
  //         let newFormValues = [...casinoInfo];
  //         newFormValues.splice(i, 1);
  //         setCasinoInfo(newFormValues);
  //         setFlag(!flag);
  //     }
  // };

  // casino setting onchange
  // const handleChange = (event) => {
  //     const { name, value } = event.target;
  //     setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  // };

  const handleFormData = (key, data) => {
    setFormData((prev) => {
      prev.error = {};

      if (
        (key === "casino_name" && !data.length) ||
        (key !== "casino_name" && !prev.casino_name.length)
      ) {
        prev.error.casino_name = "Casino name is required";
      } else {
        // else if ('casino_name' in prev?.error) {
        // delete prev.error.casino_name;

        const matchedBrandIndex =
          key === "casino_name"
            ? casinoInfo.findIndex(
                (el) =>
                  el?.brand?.toLowerCase()?.trim() === data.toLowerCase().trim()
              )
            : -1;
        if (matchedBrandIndex !== -1) {
          prev.error.casino_name = "Casino name already exists";
        }
      }

      if (
        (key === "casino_url" && !data.length) ||
        (key !== "casino_url" && !prev.casino_url.length)
      ) {
        prev.error.casino_url = "Casino url is required";
      }
      // else if ('casino_url' in prev?.error) {
      //     delete prev.error.casino_url;
      // }

      if (
        (key === "casino_token" && !data.length) ||
        (key !== "casino_token" && !prev.casino_token.length)
      ) {
        prev.error.casino_token = "Casino token is required";
      }
      //  else if ('casino_token' in prev?.error) {
      //     delete prev.error.casino_token;
      // }

      if (
        (key === "casino_key" && !data.length) ||
        (key !== "casino_key" && !prev.casino_key.length)
      ) {
        prev.error.casino_key = "Casino ID is required";
      }
      //  else if ('casino_key' in prev?.error) {
      //     delete prev.error.casino_key;
      // }

      if (!Object.keys(prev?.error).length) {
        delete prev.error;
      }
      return { ...prev, [key]: data };
    });
  };

  // const editCasinoSetting = async (e, action) => {
  //     e.preventDefault();
  //     console.log(action);
  //     console.log(formData);
  //     setIsLoading(true)
  //     await axios
  //         .post("", formData)
  //         .then((response) => {
  //             if (response.status === 200) {
  //             }
  //         })
  //         .catch((error) => {
  //             toast.error(error.message, { theme: "dark", autoClose: 1500 });
  //         })
  //         .finally(() => {
  //             setIsLoading(false);
  //         });
  // };

  useEffect(() => {
    setSelectedCasino({ ...casinoInfo?.[0] });
  }, [casinoInfo]);

  useEffect(() => {
    if (isAddCasino) {
      setFormData(defaultCasinoData);
    } else {
      setFormData({
        casino_name: selectedCasino?.casino_name,
        casino_url: selectedCasino?.casino_url,
        casino_token: selectedCasino?.casino_token,
        casino_key: selectedCasino?.casino_key,
        casino_brand_id: selectedCasino?.casino_brand_id,
        casinoID: selectedCasino?.casino_count,
      });
    }
  }, [selectedCasino, isAddCasino]);

  useEffect(() => {
    if (twoFaSetting.verifiedOtp && twoFaSetting.action) {
      formSubmitHandler(twoFaSetting.action);
      setTwoFaSetting((prev) => ({ ...prev, isClicked: false, action: "" }));
    }
  }, [twoFaSetting.verifiedOtp]);

  const formSubmitHandler = async (action) => {
    if (userInfo?.is2fa_enabled && !twoFaSetting.isClicked) {
      setTwoFaSetting((prev) => ({ ...prev, isClicked: true, action }));
      return;
    }

    if (action === "DELETE") {
      if (
        !window.confirm(
          `Are you want to permanently delete casino "${selectedCasino?.casino_name}"?`
        )
      ) {
        return;
      }
    }

    if (
      !formData.casino_name.length ||
      !formData.casino_url.length ||
      !formData.casino_token.length ||
      !formData.casino_key.length
    ) {
      setFormData((prev) => {
        prev.error = {};

        if (!prev.casino_name.length) {
          prev.error.casino_name = "Casino name is required";
        }

        if (!prev.casino_url.length) {
          prev.error.casino_url = "Casino url is required";
        }
        // else if ('casino_url' in prev?.error) {
        //     delete prev.error.casino_url;
        // }

        if (!prev.casino_token.length) {
          prev.error.casino_token = "Casino token is required";
        }
        //  else if ('casino_token' in prev?.error) {
        //     delete prev.error.casino_token;
        // }

        if (!prev.casino_key.length) {
          prev.error.casino_key = "Casino ID is required";
        }
        //  else if ('casino_key' in prev?.error) {
        //     delete prev.error.casino_key;
        // }

        if (!Object.keys(prev?.error).length) {
          delete prev.error;
        }
        return prev;
      });
      return;
    }

    // if (action !== 'DELETE' && !formData.casino_name.length) {
    // 	setFormData((prev) => {
    // 		if (!prev.brand.length) {
    // 			prev.error = {
    // 				brand: 'Brand name is required'
    // 			};
    // 		} else {
    // 			delete prev.error;
    // 		}
    // 		return { ...prev }
    // 	});
    // 	return;
    // }

    const casinoID = selectedCasino?.casino_count;
    const userID = JSON.parse(
      localStorage.getItem("user-authorization-token")
    )?.userid;
    const payload = {
      userID,
      action,
      ...(action !== "CREATE" && {
        casinoID: casinoID,
      }),
      casinoData: {
        casino_name: formData?.casino_name,
        casino_url: formData?.casino_url,
        casino_token: formData?.casino_token,
        casino_key: formData?.casino_key,
        casino_brand_id: formData?.casino_brand_id,
      },
    };

    // console.log("payload", payload);
    // console.log("payload formdata", formData, selectedCasino);
    // return;

    setIsLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_LOGIN_API_DOMAIN}/api/casino-tokens.php`,
        payload,
        {
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((e) => {
        if (e.data?.status) {
          if (action === "CREATE") {
            setIsAddCasino((prev) => !prev);
          }
          toast.success(
            `Casino ${actionResponseNames[action]?.toLowerCase()} successfully`,
            { theme: "dark", autoClose: 3000 }
          );
          updateCasinos(e.data?.data);
          if (action === "DELETE") {
            setSelectedCasino({ ...e.data?.data?.[0] });
          }
        } else {
          toast.error(e.data?.data, { theme: "dark", autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message, { theme: "dark", autoClose: 3000 });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <form
        method="post" /*onSubmit={(e) => editCasinoSetting(e, isAddCasino ? "add" : "update")}*/
      >
        <div className="table_loader_wp" style={{ minHeight: "70px" }}>
          <div className="form_box_wp home_casino_token mt_30">
            <div className="form_box">
              <Row className="mb-md-3 mb-3 justify-content-end">
                <Col lg={12}>
                  <Row>
                    <Col lg={4}>
                      <h4 className="form_title h4_title">
                        Casino Token Settings
                      </h4>
                    </Col>
                    <Col
                      lg={8}
                      className="d-flex flex-column flex-sm-row align-items-sm-start justify-content-end gap-3"
                    >
                      {!isAddCasino && (
                        <div className="dropdown_home_brand">
                          <DropDown
                            dropDownOpenTop
                            placeholder="Select Casino"
                            options={casinoInfo}
                            dataKey="casino_name"
                            isSelected={selectedCasino?.casino_name}
                            activeOption={selectedCasino?.casino_name}
                            activeOptionId={selectedCasino?.casino_name}
                            setOption={(data, index) => {
                              setSelectedCasino({ ...data });
                              // handleChange({
                              //     target: {
                              //         value: data?.casino_name || "",
                              //         name: "casino",
                              //     },
                              // });
                            }}
                          />
                        </div>
                      )}
                      {userInfo?.is2fa_enabled &&
                      !twoFaSetting.verifiedOtp &&
                      twoFaSetting.isClicked ? (
                        <Verify2fa
                          twoFaSetting={twoFaSetting}
                          setTwoFaSetting={setTwoFaSetting}
                        />
                      ) : (
                        <>
                          <div className=" button_home_brand">
                            <button
                              type="button"
                              className="sec_btn"
                              onClick={(e) => {
                                if (
                                  userInfo?.is2fa_enabled &&
                                  !twoFaSetting.isClicked
                                ) {
                                  setTwoFaSetting((prev) => ({
                                    ...prev,
                                    isClicked: true,
                                  }));
                                  return;
                                }

                                setIsAddCasino((prev) => !prev);
                              }}
                              // disabled={isDisabled}
                            >
                              {isAddCasino ? "Cancel" : "Add Casino"}
                            </button>
                          </div>
                          {!isAddCasino && (
                            <div className="button_home_brand">
                              <button
                                type="button"
                                className="sec_btn"
                                onClick={async () =>
                                  await formSubmitHandler("DELETE")
                                }
                                // disabled={isDisabled}
                              >
                                Delete Casino
                              </button>
                            </div>
                          )}
                          <div className="button_home_brand">
                            <button
                              type="button"
                              className="sec_btn"
                              onClick={async () => {
                                if (isAddCasino) {
                                  await formSubmitHandler("CREATE");
                                } else {
                                  await formSubmitHandler("UPDATE");
                                }
                              }}
                              // disabled={isDisabled}
                            >
                              {isAddCasino ? "Publish Casino" : "Update Casino"}
                            </button>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* Create Casino Title */}
              {isAddCasino && (
                <Row>
                  <div className="mb_10">
                    <h4>Create Casino</h4>
                  </div>
                </Row>
              )}
              {isLoading && <Loader />}

              {!isLoading && (
                <Row>
                  <Col lg={6}>
                    <InputField
                      type="text"
                      label="Casino Name"
                      labelIcon={<MdOutlineCasino size={20} />}
                      name="casino_name"
                      value={formData?.casino_name || ""}
                      onChange={(e) =>
                        handleFormData("casino_name", e.target.value)
                      }
                      // required
                      errorMessage={formData?.error?.casino_name}
                    />
                    {/* <div className="form_input_wp">
                                        <label className="form_input_label">
                                            <div className="form_input_icon">
                                                <MdOutlineCasino size={20} />
                                            </div>
                                            Casino Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form_input"
                                            name="casino_name"
                                            value={formData?.casino_name || ""}
                                            onChange={(e) => handleFormData('casino_name', e.target.value)}
                                            // required
                                        />
                                        {formData?.error?.casino_name && (
											<p style={{ color: "red" }}>
												{formData?.error?.casino_name}
											</p>
										)}
                                    </div> */}
                  </Col>

                  <Col lg={6}>
                    <InputField
                      type="text"
                      label="Casino Token"
                      labelIcon={<MdOutlineCasino size={20} />}
                      name="casino_token"
                      value={formData?.casino_token || ""}
                      onChange={(e) =>
                        handleFormData("casino_token", e.target.value)
                      }
                      required
                      errorMessage={formData?.error?.casino_token}
                    />
                    {/* <div className="form_input_wp">
                                        <label className="form_input_label">
                                            <div className="form_input_icon">
                                                <MdOutlineCasino size={20} />
                                            </div>
                                            Casino Token
                                        </label>
                                        <input
                                            type="text"
                                            className="form_input"
                                            name="casino_token"
                                            value={formData?.casino_token || ""}
                                            onChange={(e) => handleFormData('casino_token', e.target.value)}
                                            required
                                        />
                                        {formData?.error?.casino_token && (
											<p style={{ color: "red" }}>
												{formData?.error?.casino_token}
											</p>
										)}
                                    </div> */}
                  </Col>

                  <Col lg={4}>
                    <InputField
                      type="text"
                      label="Casino URL"
                      labelIcon={<MdOutlineCasino size={20} />}
                      name="casino_url"
                      value={formData?.casino_url || ""}
                      onChange={(e) =>
                        handleFormData("casino_url", e.target.value)
                      }
                      required
                      errorMessage={formData?.error?.casino_url}
                    />
                    {/* <div className="form_input_wp">
                                        <label className="form_input_label">
                                            <div className="form_input_icon">
                                                <MdOutlineCasino size={20} />
                                            </div>
                                            Casino URL
                                        </label>
                                        <input
                                            type="text"
                                            className="form_input"
                                            name="casino_url"
                                            value={formData?.casino_url || ""}
                                            onChange={(e) => handleFormData('casino_url', e.target.value)}
                                            required
                                        />
                                        {formData?.error?.casino_url && (
											<p style={{ color: "red" }}>
												{formData?.error?.casino_url}
											</p>
										)}
                                    </div> */}
                  </Col>

                  <Col lg={4}>
                    <InputField
                      type="text"
                      label="Casino ID"
                      labelIcon={<MdOutlineCasino size={20} />}
                      name="casino_key"
                      value={formData?.casino_key || ""}
                      onChange={(e) =>
                        handleFormData("casino_key", e.target.value)
                      }
                      required
                      errorMessage={formData?.error?.casino_key}
                    />
                    {/* <div className="form_input_wp">
                                        <label className="form_input_label">
                                            <div className="form_input_icon">
                                                <MdOutlineCasino size={20} />
                                            </div>
                                            Casino ID
                                        </label>
                                        <input
                                            type="text"
                                            className="form_input"
                                            name="casino_key"
                                            value={formData?.casino_key || ""}
                                            onChange={(e) => handleFormData('casino_key', e.target.value)}
                                            required
                                        />
                                        {formData?.error?.casino_key && (
											<p style={{ color: "red" }}>
												{formData?.error?.casino_key}
											</p>
										)}
                                    </div> */}
                  </Col>

                  {/* <Col lg={4}>
                                <div className="form_input_wp">
                                    <label className="form_input_label">
                                        <div className="form_input_icon">
                                            <MdOutlineCasino size={20} />
                                        </div>
                                        Invoice Currency
                                    </label>
                                    <DropDown
                                        placeholder="Select Currency"
                                        options={currencies}
                                        dataKey="currency_abrv"
                                        isSelected={currency}
                                        activeOption={currency}
                                        activeOptionId={currency}
                                        setOption={(data, dataIndex) => {
                                            setCurrency(data?.currency_abrv)
                                            handleChange({
                                                target: {
                                                    value: data?.currency_abrv || "",
                                                    name: "invoice_currency",
                                                },
                                            });
                                        }}
                                    />
                                </div>
                            </Col> */}

                  <Col lg={4}>
                    <div className="form_input_wp">
                      <label className="form_input_label">
                        <div className="form_input_icon">
                          <MdOutlineCasino size={20} />
                        </div>
                        Casino Brand
                      </label>
                      <div className="dropdown_home_brand">
                        <DropDown
                          dropDownOpenTop
                          placeholder="Select Brand"
                          options={brands}
                          dataKey="brand"
                          isSelected={
                            brandIdentifier(formData?.casino_brand_id)?.[0]
                              ?.brand
                          }
                          activeOption={
                            brandIdentifier(formData?.casino_brand_id)?.[0]
                              ?.brand
                          }
                          activeOptionId={
                            brandIdentifier(formData?.casino_brand_id)?.[0]
                              ?.brand
                          }
                          setOption={(data, index) => {
                            setSelectedBrand(data);
                            // setFormData()
                            handleFormData("casino_brand_id", data.brand_id);
                            // handleChange({ target: { name: "brand", value: data.brand } })
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  {/* <Col lg={3}>
                                <div className="form_input_wp">
                                    <label className="form_input_label">
                                        <div className="form_input_icon">
                                            <MdOutlineCasino size={20} />
                                        </div>
                                        Tax Rate(for example 7%)
                                    </label>
                                    <input
                                        type="text"
                                        className="form_input"
                                        name="tax_rate"
                                        value={formData?.tax_rate || ""}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </Col> */}

                  {/* <Col lg={3}>
                                <div className="form_input_wp">
                                    <label className="form_input_label">
                                        <div className="form_input_icon">
                                            <MdOutlineCasino size={20} />
                                        </div>
                                        Royalties (%)
                                    </label>
                                    <input
                                        type="number"
                                        className="form_input"
                                        name="royalties"
                                        value={formData?.royalties || ""}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col> */}

                  {/* <Col lg={3}>
                                <div className="form_input_wp">
                                    <label className="form_input_label">
                                        <div className="form_input_icon">
                                            <MdOutlineCasino size={20} />
                                        </div>
                                        Due Date (In Days)
                                    </label>
                                    <input
                                        type="number"
                                        className="form_input"
                                        name="due_date"
                                        max={999}
                                        min={1}
                                        value={formData?.due_date || ""}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col> */}

                  {/* <Col lg={3}>
                                <div className="form_input_wp">
                                    <label className="form_input_label">
                                        <div className="form_input_icon">
                                            <MdOutlineCasino size={20} />
                                        </div>
                                        Bonus Deduction (%)
                                    </label>
                                    <input
                                        type="number"
                                        className="form_input"
                                        name="bonus_deduction"
                                        value={formData?.bonus_deduction || ""}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col> */}
                  {/* <Col lg={4}>
                                <div className="form_input_wp">
                                    <label className="form_input_label">
                                        <div className="form_input_icon">
                                            <MdOutlineCasino size={20} />
                                        </div>
                                        Invoice from
                                    </label>
                                    <textarea
                                        onChange={handleChange}
                                        value={formData?.invoice_from || ""}
                                        name="invoice_from"
                                        className="form_input"
                                        cols="30"
                                        rows="10"></textarea>
                                </div>
                            </Col> */}
                  {/* <Col lg={4}>
                                <div className="form_input_wp">
                                    <label className="form_input_label">
                                        <div className="form_input_icon">
                                            <MdOutlineCasino size={20} />
                                        </div>
                                        Bill To
                                    </label>
                                    <textarea
                                        onChange={handleChange}
                                        value={formData?.bill_to || ""}
                                        name="bill_to"
                                        className="form_input"
                                        cols="30"
                                        rows="10"></textarea>
                                </div>
                            </Col> */}
                  {/* <Col lg={4}>
                                <div className="form_input_wp">
                                    <label className="form_input_label">
                                        <div className="form_input_icon">
                                            <MdOutlineCasino size={20} />
                                        </div>
                                        Bank Account or Wallet Address
                                    </label>
                                    <textarea
                                        onChange={handleChange}
                                        value={formData?.bank_account_or_wallet_address || ""}
                                        name="bank_account_or_wallet_address"
                                        className="form_input"
                                        cols="30"
                                        rows="10"></textarea>
                                </div>
                            </Col> */}
                  {/* </>
                                                )} */}
                </Row>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CasinoTokenSetting;
