import { Col, Row } from "react-bootstrap";
import { BiUser } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { RiLoader4Line } from "react-icons/ri";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import InputField from "../Common/InputField";
import { useNavigate } from "react-router-dom";

const ProfileInformation = ({ userInfo }) => {
  // const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: userInfo?.first_name || "",
    lastName: userInfo?.last_name || "",
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  const handleFormData = (key, data) => {
    setFormData((prev) => {
      if (key === "confirmPassword" && prev.password !== data) {
        prev.error = {
          [key]: "Passwords don't match",
        };
      } else {
        delete prev.error;
      }
      return { ...prev, [key]: data };
    });
  };

  const profileUpdateHandler = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setFormData((prev) => {
        prev.error = {
          confirmPassword: "Passwords don't match",
        };
        return { ...prev };
      });
      return;
    }

    // if (errorMessage) return;

    let payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      password: formData.password,
      userID: JSON.parse(localStorage.getItem("user-authorization-token"))
        ?.userid,
      action: "user",
    };

    setIsLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_LOGIN_API_DOMAIN}/api/update-profile.php`,
        payload,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data?.status) {
          localStorage?.removeItem("user-authorization-token");
          localStorage?.removeItem("currentCasino");
          localStorage?.removeItem("userInformation");
          navigate("/login");
          toast.success(response.data.data, { theme: "dark", autoClose: 3000 });
        } else {
          toast.error(response.data.data, { theme: "dark", autoClose: 3000 });
        }
      })
      .catch((error) => {
        toast.error(error.message, { theme: "dark", autoClose: 3000 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <form>
        <div className="form_box_wp mb_30">
          <div className="form_box">
            <h4 className="form_title h4_title">Profile Information</h4>
            <Row>
              <Col lg={6}>
                <InputField
                  type="text"
                  label="Username"
                  labelIcon={<BiUser size={20} />}
                  placeholder="Enter Username"
                  name="user_login"
                  value={userInfo?.user_login ?? ""}
                  readOnly
                />
              </Col>
              <Col lg={6}>
                <InputField
                  type="email"
                  label="Email"
                  labelIcon={<HiOutlineMail size={20} />}
                  placeholder="Enter Email"
                  value={userInfo?.email ?? ""}
                  readOnly
                />
              </Col>

              <Col lg={6}>
                <InputField
                  type="text"
                  label="First Name"
                  labelIcon={<MdOutlineDriveFileRenameOutline size={20} />}
                  placeholder="Enter First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={(e) => handleFormData("firstName", e.target.value)}
                />
              </Col>

              <Col lg={6}>
                <InputField
                  type="text"
                  label="Last Name"
                  labelIcon={<MdOutlineDriveFileRenameOutline size={20} />}
                  placeholder="Enter Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={(e) => handleFormData("lastName", e.target.value)}
                />
              </Col>
            </Row>
          </div>

          <div className="divider my_30"></div>

          <div className="form_box">
            <h4 className="form_title h4_title">Password Settings</h4>
            <Row>
              <Col lg={6}>
                <InputField
                  type="password"
                  label="New password"
                  labelIcon={<AiOutlineEyeInvisible size={20} />}
                  name="password"
                  value={formData.password}
                  placeholder="Enter New password"
                  onChange={(e) => handleFormData("password", e.target.value)}
                />
              </Col>
              <Col lg={6}>
                <InputField
                  type="password"
                  label="Repeat password"
                  labelIcon={<AiOutlineEyeInvisible size={20} />}
                  name="confirmPassword"
                  placeholder="Enter Repeat password"
                  value={formData.confirmPassword}
                  onChange={(e) =>
                    handleFormData("confirmPassword", e.target.value)
                  }
                  errorMessage={formData?.error?.confirmPassword}
                />
              </Col>
            </Row>
          </div>
          <div className="dashboard_form_btn form_btn table_loader_wp">
            {isLoading && (
              <div className="table_loader">
                {" "}
                <RiLoader4Line size={45} />
              </div>
            )}

            <button
              type="button"
              className="sec_btn"
              onClick={profileUpdateHandler}
              disabled={isLoading}
            >
              Update Profile Information
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ProfileInformation;
