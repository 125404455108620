import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import { Col, Row } from "react-bootstrap";
import { FiSettings } from "react-icons/fi";
import { AiOutlineExport } from "react-icons/ai";
import { RiLockPasswordLine, RiMailSendLine } from "react-icons/ri";
import Loader from "../Loader";
import axios from "axios";
import { toast } from "react-toastify";
import logo from "../../assets/images/pdf_logo.png";
import watermark from "../../assets/images/pdf-watermark.jpg";
import moment from "moment";
import { UserInfoState } from "../../context/UserInformationContext";
import Verify2fa from "./Verify2fa";
import { FaDice } from "react-icons/fa";

const GenericPdfDownloader = ({ rootElementId, invoiceData, isReady, loading, invoices, setIsGetUserInfo, disabledExport, isAllBrand }) => {
	const { userInfo } = UserInfoState();
	const [updateLoading, setUpdateLoading] = useState(false);
	const [image, setImage] = useState("");
	const [twoFaSetting, setTwoFaSetting] = useState({
		isDisplayVerifyOtp: false,
		isEnableTwoFa: userInfo?.is2fa_enabled,
		verifiedOtp: false,
		isDisplayCancelButton: true
	});

	useEffect(() => {
		if (twoFaSetting.verifiedOtp) {
			generatePDF("mail");
		}
	}, [twoFaSetting.verifiedOtp]);

	useEffect(() => {
		const toDataURL = (url) =>
			fetch(url)
				.then((response) => response.blob())
				.then(
					(blob) =>
						new Promise((resolve, reject) => {
							const reader = new FileReader();
							reader.onloadend = () => resolve(reader.result);
							reader.onerror = reject;
							reader.readAsDataURL(blob);
						})
				);

		toDataURL(watermark).then((dataUrl) => {
			setImage(dataUrl);
		});
	}, []);

	const css = `
        @page {
            size: 21cm 29.7cm;
            margin: 0;
        }

        .text{
            color: #76654d;
            font-family: sans-serif;
            font-size: 10px;
            line-height: normal;
        }

        .col-6 {
            width: 50%;
        }

        .pdf_table,
        .pdf_table tr,
        .pdf_table tbody {
            width: 100%;
            padding: 0;
            margin: 0;
            border-collapse: collapse;
        }
        .text-right {
            text-align: right;
        }
        .text-center {
            text-align: center;
        }
        .v-top {
            vertical-align: top;
        }

        .float-right {
            float: right;
        }
        .float-left {
            float: left;
        }

        .pdf_table thead tr th {
            background: #e6e6e6;
            padding: 8px 10px;
            text-align:center
        }

        .pdf_table tbody {
            border-top: 1px solid #b3a79f;
            border-bottom: 1px solid #b3a79f;
        }

        .pdf_table tbody tr td {
            padding: 4px 10px;
            border-right: 1px solid #b3a79f;
            vertical-align: -webkit-baseline-middle;
            vertical-align: -moz-middle-with-baseline;
            line-height: 1;
        }

        .pdf_table tbody tr td:last-child {
            border-right: none;
        }

        // .pdf_table tbody tr:nth-child(even) td {
        //     background: #e3e5e7;
        // }

        .border_bottom_table tbody {
            border: none;
        }

        .border_bottom_table tbody tr:nth-child(even) td {
            background: transparent;
        }

        .border_bottom_table tbody tr td {
            padding: 5px 10px;
            border-right: none;
            border-bottom: 1px solid #b3a79f;
            font-size: 11px;
        }

		.border_bottom_table tbody tr:last-child td{
			border-bottom: none
		}

        .without_border_table tbody {
            border: none;
        }

        .without_border_table tbody tr:nth-child(even) td {
            background: transparent;
        }

        .without_border_table tbody tr td {
            border: none;
        }

        .link {
            text-decoration: none !important;
            color: #76654d;
        }

        .page_break {break-inside: avoid;page-break-inside: avoid;}

        .invoice_top_table tr td{
            padding: 3px 0 !important
        }

        .pdf_table_data_body tr{
            height: 20px;
            vertical-align: -moz-middle-with-baseline;
        }

        .pdf_table_data_header tr{
            height: 28px
        }

        .brand_info, .total_tr_footer {
            background-color: #76654d !important;
        }

		.brand_info td, .total_tr_footer td {
			color: #fff;
        }

		.currency_info{
			background: #e3e5e7;
		}

		.currency_info td {
			text-shadow: 0 0 0px #74654e;
		}

		.game_type_info {
			// background: rgb(116 101 78 / 10%);
			background: rgb(227 229 231 / 40%)
		}

		.game_type_info td:first-child {
			text-align: right;
		}

		.game_type_info td {
			font-size: 9.5px;
			padding-top: 5px !important;
		}

        .invoice_title{
            text-align: left;
        }
        .invoice_title h4{
            display: inline-block;
            padding: 4px 10px;
            border-left: 3px solid #76654d
        }
        .table_main{
            margin-bottom: 15px;
            padding-bottom: 10px;
            border-bottom: 2px solid rgb(116 101 78 / 75%);
        }
        .table_main:last-of-type {
            padding: 0;
            margin: 0;
            border: none;
        }

        .brand_name {
          text-align: left !important;
        }

    `;

	const generatePDF = async (action = "") => {
		const data = {
			user_id: JSON.parse(localStorage.getItem("user-authorization-token"))?.userid,
			token: JSON.parse(localStorage.getItem("user-authorization-token"))?.token,
			invoice_number: "update",
			po_number: "update"
		};

		if (action === "export") {
			setUpdateLoading("export");
			await axios({
				url: `${process.env.REACT_APP_LOGIN_API_DOMAIN}/api/updateuserinfo.php`,
				method: "POST",
				data: data,
				mode: "no-cors",
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Methods": "POST",
					"Content-Type": "multipart/form-data"
				}
			})
				.then(async (res) => {
					if (res?.data === 1) {
						// setIsGetUserInfo((prev) => !prev);
						toast.success("Exported Successfully", {
							theme: "dark",
							autoClose: 1500
						});
					} else {
						toast.error(res?.data, { theme: "dark", autoClose: 1500 });
					}
				})
				.catch((err) => {
					toast.error(err, { theme: "dark", autoClose: 1500 });
				})
				.finally(() => {
					setUpdateLoading(false);
				});
		}

		const pdf = new jsPDF("p", "pt");

		// Remove custom added trs
		var elementsToRemove = document.querySelectorAll(".customTr");
		var elementsArray = Array.from(elementsToRemove);
		elementsArray.forEach(function (element) {
			element.parentNode.removeChild(element);
		});

		const html = document.getElementById(rootElementId)?.innerHTML;
		let htmlEl = document.getElementById(rootElementId);
		let htmlElements = document.querySelectorAll(`#${rootElementId} *`);

		const pageHeight = pdf.getPageInfo(1).pageContext.mediaBox.topRightY;
		const topTable = htmlEl.getElementsByClassName("pdf_table_data")[0].offsetTop;
		const headerHeight = htmlEl.getElementsByClassName("pdf_table_data_header")[0].offsetHeight;
		const rawHeight = htmlEl.querySelector(".pdf_table_data_body > tr").offsetHeight;
		const bodyEl = htmlEl.querySelector(".pdf_table_data_body");
		const bodyRawsEl = htmlEl.querySelectorAll(".pdf_table_data_body tr:not(.customTr)");

		let tableOffsetTop = 0;
		let page = 1;
		let increasedHeight = 0;
		let remainingHeight = 0;

		htmlElements.forEach(function (element) {
			// Check if the element has the class "margin"
			if (["TD", "TH", "THEAD", "TBODY", "STYLE"].includes(element.tagName)) {
				return;
			} else if (element.tagName === "TABLE") {
				tableOffsetTop = element.offsetTop;
				return;
			}

			if (
				element.classList.contains("top_invoices_border") ||
				element.classList.contains("pdf_table") ||
				element.classList.contains("pdf_body") ||
				element.classList.contains("customTr") ||
				element.classList.contains("bottom_invoices_border") ||
				element.classList.contains("invoice_table_total_content") ||
				element.classList.contains("page_break") ||
				element.classList.contains("exclude")
			) {
				return;
			}

			if (element.classList.contains("customTr")) {
				element.parentNode.removeChild(element);
				return;
			}

			// console.log(pageHeight, element.tagName === 'TR' ? tableOffsetTop + element.offsetTop : element.offsetTop, element.scrollHeight, element.offsetHeight, element)

			const elementTop = element.tagName === "TR" ? tableOffsetTop + element.offsetTop : element.offsetTop;
			const calculateRemainingHeight = pageHeight * page - increasedHeight - 30 - elementTop - element.offsetHeight;

			if (calculateRemainingHeight < 0) {
				remainingHeight = Math.ceil(Math.abs(pageHeight * page - increasedHeight - elementTop));
				increasedHeight += remainingHeight;

				if (element.tagName === "TR") {
					let newTr = document.createElement("tr");
					newTr.classList.add("customTr");
					newTr.style.height = Math.ceil(Math.abs(remainingHeight)) + "px";
					element.parentNode.insertBefore(newTr, /*element.nextSibling*/ element);
				} else {
					// console.log(remainingHeight, increasedHeight, remainingHeight + (increasedHeight - remainingHeight));
					element.style.paddingTop = remainingHeight + 2 + "px";
				}

				pdf.addImage(image, "JPEG", 0, 0, 600, 842);
				pdf.addPage();
				page += 1;
			}
		});

		function htmlToString(html) {
			return new XMLSerializer().serializeToString(html);
		}

		const billingEmail = invoiceData?.selectedCasino?.billing_email || "";
		const filename = `${invoiceData?.company_name?.replaceAll(" ", "")}_${moment(invoiceData?.billing_period).format("MMMM")}_${moment(invoiceData?.billing_period).format("YYYY")}`;
		let pdfData = "";
		await pdf.html(document.getElementById(rootElementId)?.innerHTML, {
			callback: () => {
				if (action === "export") {
					pdf.save(filename);
				}
				pdfData = pdf.output("datauristring");
			},
			// autoPaging: "text",
			margin: [30, 30, 30, 30]
		});

		pdf.addImage(image, "JPEG", 0, 0, 600, 842);

		if (action === "mail") {
			if (twoFaSetting.isEnableTwoFa && !twoFaSetting.verifiedOtp) {
				setTwoFaSetting((prev) => ({ ...prev, isDisplayVerifyOtp: true }));
				return;
			} else {
				setUpdateLoading("mail");
				const casino = JSON.parse(localStorage?.getItem("currentCasino"));
				await axios
					.post(`${process.env.REACT_APP_API_DOMAIN}/mails/send-invoice?token=${casino?.casino_token}&casino=${casino?.casino_key}`, {
						billingPeriod: moment(invoiceData?.billing_period).format("MMMM YYYY"),
						billingEmail,
						filename,
						pdfData
					})
					.then(async (res) => {
						if (res.data.status === 200) {
							toast.success("Invoice mail sent..", {
								theme: "dark",
								autoClose: 7000
							});
						} else if (res.data.status === 500) {
							toast.error(res?.data?.message, {
								theme: "dark",
								autoClose: 7000
							});
						} else {
							toast.error(res?.data, { theme: "dark", autoClose: 7000 });
						}
					})
					.catch((err) => {
						toast.error(err, { theme: "dark", autoClose: 7000 });
					})
					.finally(() => {
						setUpdateLoading(false);
					});
			}
		}

		htmlEl.innerHTML = html;
	};

	let formatNum = new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
		round: "round"
	});

	return (
		<>
			<div className="form_btn button_group mb-4">
				<button
					type="submit"
					className="sec_btn"
				>
					Generate PDF {loading ? <Loader size={22} /> : <FiSettings />}
				</button>
				<button
					type="button"
					className="sec_btn"
					onClick={() => generatePDF("export")}
					disabled={disabledExport}
				>
					Export PDF {updateLoading === "export" ? <Loader size={22} /> : <AiOutlineExport />}
				</button>
				{JSON.parse(localStorage.getItem("user-authorization-token"))?.userid === 1 && (
					<button
						type="button"
						className="sec_btn"
						onClick={() =>
							userInfo?.is2fa_enabled && !twoFaSetting.verifiedOtp
								? setTwoFaSetting((prev) => ({
										...prev,
										isDisplayVerifyOtp: true
								  }))
								: generatePDF("mail")
						}
						disabled={disabledExport}
					>
						Send Mail {updateLoading === "mail" ? <Loader size={22} /> : userInfo?.is2fa_enabled && !twoFaSetting.verifiedOtp ? <RiLockPasswordLine /> : <RiMailSendLine />}
					</button>
				)}
			</div>

			{twoFaSetting.isDisplayVerifyOtp && (
				<Verify2fa
					twoFaSetting={twoFaSetting}
					setTwoFaSetting={setTwoFaSetting}
				/>
			)}
			{isReady && (
				<Row>
					<Col lg={12}>
						<div className="invoice_wrapper">
							<div
								id="invoice"
								className="remove_bg_img"
							>
								<style jsx>{css}</style>

								<span className="top_invoices_border"></span>

								<div
									className="pdf_body"
									style={{
										width: "535px"
									}}
								>
									<div
										className="pdf_table"
										style={{ display: "flow-root", width: "100%" }}
									>
										<table className="without_border_table pdf_table">
											<tbody>
												<tr>
													<td
														className="v-top"
														style={{
															width: "50%",
															textAlign: "left",
															paddingLeft: 0
														}}
													>
														<img
															src={logo}
															alt="Urgent Games"
															width="140"
															height="88"
														/>

														<div
															style={{
																display: "block",
																marginTop: "20px"
															}}
														>
															<div style={{ marginBottom: "20px" }}>
																<h4
																	className="text"
																	style={{
																		marginBottom: "2px",
																		fontSize: "15px"
																	}}
																>
																	{/* {invoiceData[0]?.company_name} */}
																	Invoice From
																</h4>
																<pre
																	className="text"
																	style={{
																		maxWidth: "170px",
																		fontSize: "12px",
																		lineHeight: "18px",
																		textWrap: "wrap"
																	}}
																>
																	{invoiceData?.company_info}
																</pre>
															</div>
														</div>
													</td>

													<td
														style={{ width: "50%", paddingRight: 0 }}
														className="v-top text-right"
													>
														<div>
															<h1
																className="text"
																style={{
																	marginBottom: "13px",
																	fontSize: "30px"
																}}
															>
																Invoice
															</h1>

															<div
																style={{
																	maxWidth: "130px",
																	marginLeft: "auto"
																}}
															>
																<table
																	className="without_border_table pdf_table invoice_top_table"
																	style={{ textAlign: "right" }}
																>
																	<tbody>
																		<tr>
																			<td className="text">
																				<b>Invoice #</b>
																			</td>
																			<td className="text">{invoiceData?.invoice_number}</td>
																		</tr>
																		<tr>
																			<td className="text">
																				<b>Invoice Date</b>
																			</td>
																			<td className="text">{moment(invoiceData?.invoiceDate).format("DD/MM/YYYY")}</td>
																		</tr>
																		<tr>
																			<td className="text">
																				<b>Due Date</b>
																			</td>
																			<td className="text">{moment(invoiceData?.dueDate).format("DD/MM/YYYY")}</td>
																		</tr>
																		<tr>
																			<td
																				className="text"
																				style={{
																					paddingTop: "15px"
																				}}
																			>
																				<b>Billing Period</b>
																			</td>
																			<td
																				className="text"
																				style={{
																					paddingTop: "15px"
																				}}
																			>
																				{invoiceData?.billing_period}
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>

														<div
															style={{
																marginTop: "40px",
																maxWidth: "190px",
																textAlign: "left",
																marginLeft: "auto"
															}}
														>
															<h3
																className="text"
																style={{
																	marginBottom: "2px",
																	fontSize: "13px",
																	textAlign: "left"
																}}
															>
																Bill To
															</h3>
															<pre
																className="text"
																style={{
																	fontSize: "12px",
																	lineHeight: "18px",
																	marginLeft: "auto",
																	textWrap: "wrap"
																}}
															>
																{invoiceData?.bill_to}
															</pre>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<div className="table_main_wrapper exclude">
										{invoices?.map((invoice, iIndex) => (
											<React.Fragment>
												{console.log(invoice?.data, "invoice")}
												<div className="table_main exclude">
													{/* <div className="invoice_title">
                                                    <h4 className="text exclude" style={{fontSize: "15px"}}>Currency {invoice?.invoiceCurrency}</h4>
                                                </div> */}
													<div
														className="page_break"
														style={{ display: "block", width: "100%" }}
													>
														<table
															className="pdf_table pdf_table_data"
															style={{ textAlign: "right" }}
														>
															<thead className="pdf_table_data_header">
																<tr>
																	<th className="text">Product</th>
																	<th className="text">Debit</th>
																	<th className="text">Credit</th>
																	<th className="text">GGR</th>
																	<th className="text">GGR {invoice?.invoiceCurrency}</th>
																	<th className="text">Fee UG {invoice?.invoiceCurrency}</th>
																	<th
																		className="text"
																		width="40"
																	>
																		Bonus Ded.
																	</th>
																	{/* <th className="text">Fee UG</th>
																	<th className="text">To {invoice?.invoiceCurrency}</th>
																	<th className="text">Bonus Ded.</th> */}
																</tr>
															</thead>
															<tbody className="pdf_table_data_body">
																{
																	// isAllBrand ? (
																	<>
																		{[...invoice?.data]?.map((brand, bIndex) => {
																			const brandJsx = [];

																			brandJsx.push(
																				<tr className="brand_info">
																					<td
																						colSpan={7}
																						className="text brand_name"
																						style={{ fontWeight: 700 }}
																					>
																						{brand.casino}
																					</td>
																				</tr>
																			);

																			{
																				/* brandJsx.push( */
																			}
																			brand.currencies.map((item, index) => {
																				brandJsx.push(
																					<tr
																						key={"brand" + bIndex + index}
																						className="currency_info"
																					>
																						<td className="text">
																							Gaming License Fee {/*item?.royaltiesKey*/} ({item?.currency})
																						</td>
																						<td className="text">{formatNum.format(item?.loses)}</td>
																						<td className="text">{formatNum.format(item?.wins)}</td>
																						<td
																							className="text"
																							style={{ textAlign: "right" }}
																						>
																							{formatNum.format(item?.GGR)}
																						</td>
																						<td
																							className="text"
																							style={{ textAlign: "right" }}
																						>
																							{formatNum.format(item?.finalGGRWithCurrency)}
																						</td>
																						{/* <td
																							className="text"
																							style={{ textAlign: "right" }}
																						>
																							{formatNum.format(item?.royaltiesCurrentCurrency)}
																						</td> */}
																						<td
																							className="text"
																							style={{ textAlign: "right" }}
																						>
																							{formatNum.format(item?.royalties)}
																						</td>
																						<td
																							className="text"
																							style={{ textAlign: "right" }}
																						>
																							{formatNum.format(item?.bonusDeduction)}
																						</td>
																					</tr>
																				);

																				Object.entries(item.gameTypes).map(([type, typeData]) => {
																					const bonusDeductionGames = Object.entries(typeData?.games)
																						.filter(([_, game]) => game.bonusDeduction !== 0)
																						?.map(([key, value]) => ({ ...value, gameId: key }));

																					brandJsx.push(
																						<tr
																							key={"brand" + bIndex + type + index}
																							className="game_type_info"
																						>
																							<td className="text">
																								{type} {typeData?.applicableRoyalties || 0}%
																							</td>
																							<td className="text">{formatNum.format(typeData?.loses)}</td>
																							<td className="text">{formatNum.format(typeData?.wins)}</td>
																							<td
																								className="text"
																								style={{
																									textAlign: "right"
																								}}
																							>
																								{formatNum.format(typeData?.GGR)}
																							</td>
																							<td
																								className="text"
																								style={{
																									textAlign: "right"
																								}}
																							>
																								{item?.finalGGRWithCurrency !== item?.GGRWithCurrency ? (
																									<>
																										<mark
																											style={{
																												lineHeight: 1.2
																											}}
																										>
																											<del>{formatNum.format(item?.GGRWithCurrency)}</del> {formatNum.format(item?.finalGGRWithCurrency)}
																										</mark>
																									</>
																								) : (
																									formatNum.format(item?.GGRWithCurrency)
																								)}
																							</td>
																							{/* <td
																								className="text"
																								style={{ textAlign: "right" }}
																							>
																								{formatNum.format(typeData?.royaltiesCurrentCurrency)}
																							</td> */}
																							<td
																								className="text"
																								style={{
																									textAlign: "right"
																								}}
																							>
																								{formatNum.format(typeData?.royalties)}
																							</td>
																							<td
																								className="text"
																								style={{
																									textAlign: "right"
																								}}
																							>
																								{formatNum.format(typeData?.bonusDeduction)}
																							</td>
																						</tr>
																					);
																					bonusDeductionGames?.map((bonusDeduGame, index) => {
																						brandJsx.push(
																							<tr
																								key={`bonus-deduction-games-${index}`}
																								style={{ background: "#cfcfcf" }}
																							>
																								<td className="text">
																									<div className="d-flex align-items-center justify-content-between">
																										<span></span>
																										{bonusDeduGame?.gameId}
																									</div>
																								</td>
																								<td className="text">{formatNum.format(bonusDeduGame?.loses)}</td>
																								<td className="text">{formatNum.format(bonusDeduGame?.wins)}</td>
																								<td
																									className="text"
																									style={{
																										textAlign: "right"
																									}}
																								>
																									{formatNum.format(bonusDeduGame?.GGR)}
																								</td>
																								<td
																									className="text"
																									style={{
																										textAlign: "right"
																									}}
																								>
																									{item?.finalGGRWithCurrency !== item?.GGRWithCurrency ? (
																										<>
																											<mark
																												style={{
																													lineHeight: 1.2
																												}}
																											>
																												<del>{formatNum.format(item?.GGRWithCurrency)}</del> {formatNum.format(item?.finalGGRWithCurrency)}
																											</mark>
																										</>
																									) : (
																										formatNum.format(bonusDeduGame?.GGRWithCurrency)
																									)}
																								</td>
																								{/* <td
																								className="text"
																								style={{ textAlign: "right" }}
																							>
																								{formatNum.format(typeData?.royaltiesCurrentCurrency)}
																							</td> */}
																								<td
																									className="text"
																									style={{
																										textAlign: "right"
																									}}
																								>
																									{formatNum.format(bonusDeduGame?.royalties)}
																								</td>
																								<td
																									className="text"
																									style={{
																										textAlign: "right"
																									}}
																								>
																									{formatNum.format(bonusDeduGame?.bonusDeduction)}
																								</td>
																							</tr>
																						);
																					});
																				});
																			});
																			{
																				/* ); */
																			}

																			return brandJsx;
																		})}
																		<tr
																			key={"casinos-total-row"}
																			className="total_tr_footer"
																		>
																			<td
																				className="text brand_name"
																				style={{
																					textAlign: "right",
																					fontWeight: 700
																				}}
																			>
																				Total
																			</td>
																			<td
																				className="text brand_name"
																				style={{
																					textAlign: "right",
																					fontWeight: 700
																				}}
																			>
																				{formatNum.format(invoice?.loses)}
																			</td>
																			<td
																				className="text brand_name"
																				style={{
																					textAlign: "right",
																					fontWeight: 700
																				}}
																			>
																				{formatNum.format(invoice?.wins)}
																			</td>
																			<td
																				className="text brand_name"
																				style={{
																					textAlign: "right",
																					fontWeight: 700
																				}}
																			>
																				{formatNum.format(invoice?.GGR)}
																			</td>
																			<td
																				className="text brand_name"
																				style={{
																					textAlign: "right",
																					fontWeight: 700
																				}}
																			>
																				{/* {formatNum.format(invoice?.royaltiesOfCurrencies)} */}
																				{formatNum.format(invoice?.GGRWithCurrency) !== formatNum.format(invoice?.finalGGRWithCurrency) && (
																					<>
																						<del>{formatNum.format(invoice?.GGRWithCurrency)}</del>
																						<br />
																					</>
																				)}
																				{formatNum.format(invoice?.finalGGRWithCurrency)}
																			</td>
																			<td
																				className="text brand_name"
																				style={{
																					textAlign: "right",
																					fontWeight: 700
																				}}
																			>
																				{formatNum.format(invoice?.royalties)}
																			</td>
																			<td
																				className="text brand_name"
																				style={{
																					textAlign: "right",
																					fontWeight: 700
																				}}
																			>
																				{formatNum.format(invoice?.bonusDeduction)}
																			</td>
																		</tr>
																	</>
																}
															</tbody>
														</table>
													</div>

													<div id="invoice_table_total">
														<div
															className="invoice_table_total_content"
															style={{
																display: "flow-root",
																width: "100%",
																textAlign: "left"
															}}
														>
															<div
																className="col-6 float-left"
																style={{ paddingRight: 10 }}
															>
																<p
																	className="text invoice_table_total_content"
																	style={{
																		padding: "3px 10px",
																		fontSize: "13px",
																		textAlign: "left",
																		display: "inline-block"
																	}}
																>
																	Exchange rate&nbsp;
																	<a
																		className="invoice_table_total_content link"
																		href={invoiceData?.exchange_rate_domain}
																		target="moopener"
																		title={invoiceData?.exchange_rate_domain}
																		style={{ fontSize: "13px", float: "right" }}
																	>
																		{invoiceData?.exchange_rate_domain}
																	</a>
																</p>
																{/* <table
																	className="border_bottom_table pdf_table"
																	style={{ textAlign: "left", marginTop: 3.85 }}
																>
																	<tbody>
																		<tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">GGR ({formatNum.format(invoice?.GGR)})</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.invoiceCurrency} {formatNum.format(invoice?.GGRWithCurrency)}
																			</td>
																		</tr>
																		<tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">Subtract from GGR</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.invoiceCurrency} {formatNum.format(invoice?.subtractFromGGR)}
																			</td>
																		</tr>
																		<tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">Subtracted GGR</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.invoiceCurrency} {formatNum.format(invoice?.subtractedGGR ?? 0)}
																			</td>
																		</tr>
																	</tbody>
																</table> */}
															</div>
															<div className="col-6 float-right">
																<table
																	className="border_bottom_table pdf_table"
																	style={{ textAlign: "right" }}
																>
																	<tbody>
																		{/* --------------------------------------------------- */}
																		{/* <tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">GGR ({formatNum.format(invoice?.GGR)})</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.invoiceCurrency} {formatNum.format(invoice?.GGRWithCurrency)}
																			</td>
																		</tr>
																		<tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">Subtract from GGR</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.invoiceCurrency} {formatNum.format(invoice?.subtractFromGGR)}
																			</td>
																		</tr>
																		<tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">Subtracted GGR</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.invoiceCurrency} {formatNum.format(invoice?.GGRWithCurrency - invoice?.subtractFromGGR)}
																			</td>
																		</tr>
																		<tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">Sub-Total</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.invoiceCurrency}{" "}
																				{formatNum.format(
																					parseFloat(invoice?.royaltiesKey) !== 0
																						? ((invoice?.GGRWithCurrency - invoice?.subtractFromGGR) * parseFloat(invoice?.royaltiesKey)) / 100
																						: 0
																				)}
																			</td>
																		</tr>
																		<tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">Bonus Deduct</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.invoiceCurrency}{" "}
																				{formatNum.format(
																					parseFloat(invoice?.royaltiesKey) !== 0 && parseFloat(invoice?.bonusDeductionKey) !== 0
																						? ((((invoice?.GGRWithCurrency - invoice?.subtractFromGGR) * parseFloat(invoice?.royaltiesKey)) / 100) *
																								parseFloat(invoice?.bonusDeductionKey)) /
																								100
																						: 0
																				)}
																			</td>
																		</tr>
																		<tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">Bonus deduct (in %)</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.bonusDeductionKey}
																			</td>
																		</tr>
																		<tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">Total</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.invoiceCurrency}{" "}
																				{formatNum.format(
																					parseFloat(invoice?.royaltiesKey) !== 0 && parseFloat(invoice?.bonusDeductionKey) !== 0
																						? ((invoice?.GGRWithCurrency - invoice?.subtractFromGGR) * parseFloat(invoice?.royaltiesKey)) / 100 -
																								((((invoice?.GGRWithCurrency - invoice?.subtractFromGGR) * parseFloat(invoice?.royaltiesKey)) / 100) *
																									parseFloat(invoice?.bonusDeductionKey)) /
																									100
																						: parseFloat(invoice?.royaltiesKey) !== 0
																						? ((invoice?.GGRWithCurrency - invoice?.subtractFromGGR) * parseFloat(invoice?.royaltiesKey)) / 100
																						: invoice?.GGRWithCurrency - invoice?.subtractFromGGR
																				)}
																			</td>
																		</tr> */}
																		{/* --------------------------------------------------- */}

																		<tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">Sub-Total</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.invoiceCurrency} {formatNum.format(invoice?.royaltiesWithoutBonusDeduct)}
																			</td>
																		</tr>
																		{/* <tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">Subtract from GGR</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.invoiceCurrency} {formatNum.format(invoice?.subtractFromGGR)}
																			</td>
																		</tr> */}
																		<tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">Subtract from GGR</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.invoiceCurrency} {formatNum.format(invoice?.subtractFromGGR)}
																			</td>
																		</tr>
																		<tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">Bonus deduct</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.invoiceCurrency} {formatNum.format(invoice?.bonusDeduction)}
																			</td>
																		</tr>
																		<tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">Bonus deduct (in %)</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.bonusDeductionKey}
																			</td>
																		</tr>
																		<tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">Payments/Credits</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.invoiceCurrency} 0.00
																			</td>
																		</tr>
																		<tr className="invoice_table_total_content">
																			<td className="text">
																				<b className="invoice_table_total_content">Total</b>
																			</td>
																			<td
																				className="text"
																				style={{ textAlign: "right" }}
																			>
																				{invoice?.invoiceCurrency} {formatNum.format(invoice?.netRoyalties)}
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</React.Fragment>
										))}
									</div>
									<div
										className="bank-account-section exclude"
										style={{
											/*display: "flow-root", */ width: "100%",
											marginTop: "10px",
											textAlign: "left",
											maxWidth: "400px"
										}}
									>
										{/* <p
                                            style={{
                                                color: "#000",
                                                fontSize: "13px",
                                                lineHeight: 1,
                                                marginBottom: 0,
                                            }}
                                        >
                                            FTN Wallet:
                                        </p> */}
										{/* {console.log(invoiceData?.bank_account_or_wallet)} */}
										{/* <p style={{ color: "#474748", fontSize: "10px", marginBottom: 0 }}>{invoiceData?.bank_account_or_wallet}</p> */}
										<pre
											className=""
											style={{
												color: "#333333",
												fontSize: "10px",
												marginBottom: 0,
												textWrap: "wrap"
											}}
										>
											{invoiceData?.bank_account_or_wallet}
										</pre>
									</div>
								</div>
								<span className="bottom_invoices_border"></span>
							</div>
						</div>
					</Col>
				</Row>
			)}
		</>
	);
};

export default GenericPdfDownloader;
