import DashBoard from "../../pages/Home";
import Invoices from "../../pages/Invoices";
import MainDashboard from "../../pages/MainDashboard";
import CasinoGameReports from "../../pages/CasinoGameReports";
import CasinoGameRounds from "../../pages/CasinoGameRounds";
import PlayerReports from "../../pages/PlayerReports";
import CasinoSetting from "../../pages/Casino";
import Finance from "../../pages/Finance";
import FinancialReports from "../../pages/FinancialReports";
import ReactPdf from "../../pages/react-pdf-html";
import GameReports from "../../pages/GameReports";
import Player from "../../pages/Player";
import FinancialStatements from "../../pages/FinancialStatements";
import UserPermission from "../../pages/UserPermission";
import TwoFaSettings from "../../pages/2faSettings";
import TransactionsPerCasino from "../../pages/TransactionsPerCasino";

export const PrivateRouteData = [
	{
		menu_num: "01",
		components: <DashBoard />,
		path: "/"
	},
	{
		menu_num: "02",
		components: <Invoices />,
		path: "/invoices",
		page_name: "invoices"
	},
	{
		menu_num: "03",
		components: <MainDashboard />,
		path: "/casino",
		page_name: "main_dashboard"
	},
	{
		menu_num: "04",
		components: <CasinoGameReports />,
		path: "/casino-game-reports",
		page_name: "casino_game_reports"
	},
	{
		menu_num: "05",
		components: <CasinoGameRounds />,
		path: "/casino-game-rounds",
		page_name: "game_rounds"
	},
	{
		menu_num: "06",
		components: <TransactionsPerCasino />,
		path: "/transactions-per-casino",
		page_name: "transactions_per_casino"
	},
	{
		menu_num: "07",
		components: <PlayerReports />,
		path: "/Player-reports",
		page_name: "player_reports"
	},
	{
		menu_num: "08",
		components: <Finance />,
		path: "/finance",
		page_name: "finance_admin_reporting"
	},
	{
		menu_num: "09",
		components: <FinancialReports />,
		path: "/financial-reports",
		page_name: "financial_reports_admin_reporting"
	},
	{
		menu_num: "10",
		components: <CasinoSetting />,
		path: "/casino-settings",
		page_name: "casino_settings"
	},
	{
		menu_num: "11",
		components: <TwoFaSettings />,
		path: "/2fa-settings",
		page_name: "2fa_settings"
	},
	{
		menu_num: "12",
		components: <ReactPdf />,
		path: "/react-pdf"
	},
	{
		menu_num: "13",
		components: <GameReports />,
		path: "/game-reports",
		page_name: "game_reports_admin_reporting"
	},
	{
		menu_num: "14",
		components: <Player />,
		path: "/player"
	},
	{
		menu_num: "15",
		components: <FinancialStatements />,
		path: "/financial-statements",
		page_name: "financial_statements"
	},
	{
		menu_num: "16",
		components: <UserPermission />,
		path: "/user-permission",
		page_name: "user_permissions_admin_reporting"
	}
];
