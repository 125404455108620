import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import Loader from "../Loader";
import { Col, Row } from "react-bootstrap";
import { GiCreditsCurrency } from "react-icons/gi";
import DropDown from "../Common/DropDown";
import MultiSelectDropDown from "../Common/MultiSelectDropDown";

const GameBrandsGGRChart = ({ refreshToggle, width, startDate, endDate, currencies }) => {
	const [chartdata, setChartdata] = useState([]);
	const [optionsData, setOptionsData] = useState({ series: [], labels: [], original: { series: [], labels: [] }, disabledIndexes: [] });
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [currency, setCurrency] = useState("All");
	const [selectedBrand, setSelectedBrand] = useState();
	const allCasinos = JSON.parse(localStorage.getItem("userInformation"));
	const brands = Object.groupBy(allCasinos?.casino_information, (casino) => casino.brand);

	const getData = () => {
		const casino = JSON.parse(localStorage?.getItem("currentCasino"));
		setLoading(true);
		setErrorMessage("");
		axios
			.post(
				`${process.env.REACT_APP_API_DOMAIN}/reports/transactions/dashboard/ggr-brand-games?token=${casino?.casino_token}&casino=${casino?.casino_key}&currency=${
					currency === "All" ? "" : currency
				}&start=${startDate}T00:00:00Z&end=${endDate}T23:59:59Z`,
				{ brands }
			)
			.then((response) => {
				if (response.data.status === 200) {
					setChartdata(response.data?.reportForChartBrandGamesGGR);
				} else {
					setErrorMessage(response?.data?.message);
				}
			})
			.catch((error) => {
				setChartdata([]);
				setErrorMessage(error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		setErrorMessage("");
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshToggle, currency]);

	useEffect(() => {
		setSelectedBrand(Object.keys(chartdata)[0]);
	}, [chartdata]);

	useEffect(() => {
		if (Object.keys(chartdata).length) {
			const gameData = Object.keys(chartdata).find((game) => game === selectedBrand) || Object.keys(chartdata)[0];
					const gameBrandsGGR = Object.keys(chartdata[gameData]).map((gameBrand) => {
			const ggr = chartdata[gameData][gameBrand]?.GGR;
			return ggr !== null && ggr !== undefined ? parseFloat(ggr.toFixed(2)) : 0;
		});
			setErrorMessage("");
			const modifiedData = { series: gameBrandsGGR, labels: Object.keys(chartdata[gameData]) };
			setOptionsData((p) => ({ ...p, ...modifiedData, original: modifiedData, disabledIndexes: modifiedData.labels.map((_, i) => i) }));
		} else {
			const emptyData = { series: [], labels: [] };
			setOptionsData((p) => ({ ...p, ...emptyData, original: { ...emptyData } }));
			setErrorMessage("There are no records to display");
		}
	}, [chartdata, selectedBrand]);

	const toggleData = (index) => {
		setOptionsData((prev) => {
			if (!prev.disabledIndexes.includes(index)) {
				const disabledIndexes = [...prev.disabledIndexes, index];
				const newSeries = prev.original.series.filter((series, sIndex) => disabledIndexes.includes(sIndex));
				const newLabels = prev.original.labels.filter((label, lIndex) => disabledIndexes.includes(lIndex));
				return { ...prev, series: newSeries, labels: newLabels, disabledIndexes: disabledIndexes };
			} else {
				let updatedDisabledIndexes = [...prev.disabledIndexes];

				updatedDisabledIndexes = updatedDisabledIndexes.filter((el) => el !== index);

				const newSeries = prev.original.series.filter((series, sIndex) => updatedDisabledIndexes.includes(sIndex));
				const newLabels = prev.original.labels.filter((label, lIndex) => updatedDisabledIndexes.includes(lIndex));
				return { ...prev, series: newSeries, labels: newLabels, disabledIndexes: updatedDisabledIndexes };
			}
		});
	};

	const chartProps = {
		series: optionsData.series,
		options: {
			chart: {
				width: "100%",
				type: "pie"
			},
			labels: optionsData.labels,
			dataLabels: {
				formatter(val, opts) {
					const name = opts.w.globals.labels[opts.seriesIndex];
					return [name, parseFloat(val).toFixed(1) + "%"];
				}
			},
			legend: {
				horizontalAlign: "left",
				offsetX: 20,
				labels: {
					colors: "#fff"
				},
				markers: {
					offsetX: -3,
					offsetY: 2
				},
				itemMargin: {
					horizontal: 10
				}
			},
			responsive: [
				{
					breakpoint: 575,
					options: {
						legend: {
							position: "bottom"
						}
					}
				}
			]
		}
	};

	return (
		<>
			<div className="finance_heapchart game_reports_charts mb_30">
				<Row className="align-items-center">
					<Col lg={4}>
						<h5 className="h5_title">Games GGR by Brand</h5>
					</Col>
				</Row>
				<Row className="align-items-center">
					<Col lg={4}>
						<div className="datepicker_right">
							<label className="form_input_label">
								<div className="form_input_icon">
									<GiCreditsCurrency size={20} />
								</div>
								Select Brand
							</label>
							<DropDown
								placeholder="Select Brand"
								options={Object.keys(chartdata).map((key) => ({ name: key }))}
								dataKey="name"
								isSelected={selectedBrand}
								activeOption={selectedBrand}
								activeOptionId={selectedBrand}
								setOption={(data, index) => {
									setSelectedBrand(data?.name);
								}}
							/>
						</div>
					</Col>
					<Col lg={4}>
						<div className="datepicker_right">
							<label className="form_input_label">
								<div className="form_input_icon">
									<GiCreditsCurrency size={20} />
								</div>
								Select Games
							</label>

							<MultiSelectDropDown
								placeholder="Select Games"
								options={optionsData.original.labels?.map((item) => ({ label: item, value: item }))}
								isSelected={optionsData.disabledIndexes?.map((item) => ({ label: item, value: optionsData.original.labels[item] }))}
								activeOptions={optionsData.disabledIndexes?.map((item) => ({ label: item, value: optionsData.original.labels[item] }))}
								setOptionsData={setOptionsData}
								dataKey="value"
								disableAll
								setSelectedOptions={(data, i) => {
									toggleData(i);
								}}
							/>
						</div>
					</Col>
					<Col lg={4}>
						<div className="datepicker_right">
							<label className="form_input_label">
								<div className="form_input_icon">
									<GiCreditsCurrency size={20} />
								</div>
								Select Currency
							</label>

							<DropDown
								placeholder="Select Currency"
								options={currencies}
								dataKey="currency_abrv"
								isSelected={currency}
								activeOption={currency}
								activeOptionId={currency}
								setOption={(data, index) => {
									setCurrency(data?.currency_abrv);
								}}
							/>
						</div>
					</Col>
				</Row>
			</div>

			{!loading && errorMessage ? (
				<p style={{ textAlign: "center", color: "red" }}> {errorMessage}</p>
			) : (
				<>
					<Chart
						{...chartProps}
						type="pie"
						width="100%"
						height="500px"
					/>
				</>
			)}

			{loading ? <Loader /> : ""}
		</>
	);
};

export default GameBrandsGGRChart;
